<template>
<div class="l-page-layout l-page-layout--two-column-fixed">
  
    <div class="l-page-layout__secondary">
    <header class="c-header-dark" role="banner">
      <div class="c-header-vertical__inner">

        <a :href="homeLogoLink" class="c-logo__link" rel="home">
          <div class="logo-container">
            <div class="c-logo-vertical"> <!-- logo-container -->
              <img class="c-logo__img" src="@Images/square_accents/squares_3-1.svg" alt="TK Logo"> <!-- logo --> 
            </div>
            <div>
              <span class="logo-text">
                T-K
              </span>
            </div>
          </div>
        </a><!--end c-logo__link-->

        <!-- dropdowns -->
        <div class="c-header-vertical__nav-container js-nav-panel">

          <nav class="c-primary-nav-vertical" role="navigation" aria-label="Left navigation">

            <ul class="c-primary-nav-vertical__list js-nav-panel dropdown-nav" role="tablist" aria-multiselectable="true">
                <li class="dropdown-item">
                  <label for="select" class="c-label">Select account</label>
                  <select id="account-dropdown" class="c-input--select" v-model="currentAccount">
                    <option 
                      v-for="option in dropdownOptions['account']"
                      :key="option.value"
                      :value="option"
                    >
                      {{ option.label | truncate(18) }}
                    </option>
                  </select>
                </li>
                <li class="dropdown-item" 
                v-bind:style="{ visibility: workGroupVisiblity }"
                >
                  <label for="select" class="c-label">Select workgroup</label>
                  <MultiSelect
                      v-if="!loadingWorkGroup"
                      :key="multiSelectComponentKey"
                      :dropdownOptions="dropdownOptions.workgroup"
                      type="workGroup"
                      :isSearchable="true"
                      groupValues="workGroups"
                      :valuesProp="workGroupValues"
                      @input="updateSelectedWorkGroup"
                  />
                  <FunctionalArea 
                      v-if="currentAccountValue"
                      :accountId="currentAccountValue"
                      :workGroupIds="workGroupIds"
                      :accountConfiguration="accountConfiguration"
                      :workGroupsProp="workGroupsProp"
                      @selectFA="selectFunctionalArea"
                  />
                </li>
            </ul>
          </nav>
        </div>
        <!-- end dropdowns -->

    
        

        <div class="c-header-vertical__nav-container js-nav-panel">

          <nav class="c-primary-nav-vertical" role="navigation" aria-label="Left navigation">

            <ul class="c-primary-nav-vertical__list js-nav-panel" role="tablist" aria-multiselectable="true">
                 
                 <li v-if="currentAccount || currentWorkgroups.length > 0">

                    <side-navigation-item 
                      icon="../../assets/style/ckm-style/images/icons/icons.svg#home"
                      label="Home"
                      :activePage='getActivePage'
                      :link="homeLink"
                      @setActivePage="setActivePage"
                      />
                      <side-navigation-item 
                      icon="../../assets/style/ckm-style/images/icons/icons.svg#exec"
                      label="Account Summary"
                      :activePage='getActivePage'
                      :link="accountSummaryLink"
                      @setActivePage="setActivePage"
                      v-if="showAccountSummary"
                      />
                      <side-navigation-item 
                      icon="../../assets/style/ckm-style/images/icons/icons.svg#pmBulb"
                      label="Process Mining >"
                      :activePage='getActivePage'
                      :link="processMiningLink"
                      :subMenuItems="processMiningSubMenuItems"
                      @setActivePage="setActivePage"
                      v-if="showProcessMiningDashboard"
                      />
                      <side-navigation-item 
                      icon="../../assets/style/ckm-style/images/icons/icons.svg#catstatChart"
                      label="Category Metrics >"
                      :activePage='getActivePage'
                      :link="catMetricsLink"
                      :subMenuItems="catMetricsSubMenuItems"
                      @setActivePage="setActivePage"
                      v-if="showCatStatsDashboard"
                      />
                      <side-navigation-item 
                      icon="../../assets/style/ckm-style/images/icons/icons.svg#capacityHands"
                      label="Capacity Management >"
                      :activePage='getActivePage'
                      :link="capacityLink"
                      :subMenuItems="capacitySubMenuItems"
                      @setActivePage="setActivePage"
                      v-if="showServiceDeskDashboard"
                    />
                    <side-navigation-item 
                      icon="../../assets/style/ckm-style/images/icons/icons.svg#wamClock"
                      label="Repetitive Work"
                      :activePage='getActivePage'
                      :link="repetitiveWorkLink"
                      @setActivePage="setActivePage"
                      v-if="showWAMalertsDashboard"
                    />

                  </li>


            </ul><!-- end c-nav__list -->

          </nav><!-- end c-nav -->

        </div><!--end c-header__nav-container-->

      </div><!--end c-header__inner-->

    </header><!--end c-header-->
    </div><!-- end secondary l-page column -->
  </div>
</template>

<script>
import MultiSelect from '@Common/MultiSelect.vue'
import FunctionalArea from '@Common/functionalArea/FunctionalArea.vue'
import SideMenu from '@Common/SideMenu.vue'
import SideNavigationItem from '@Common/SideNavigationItem.vue'
import RouteQueryParamMixin from '@Mixins/RouteQueryParamMixin.vue'
import { axiosRequests } from '@/main'
import moment from 'moment-timezone'
import _ from 'lodash'

export default {
    components: {
      sideNavigationItem: SideNavigationItem,
        sideMenu: SideMenu,
        MultiSelect, FunctionalArea
    },
    props: {
      accountId: {type: [Number, String], required: false},
      workGroupIds: {type: String, required: false},
      dataRange: {type: Object, required: true},
      accountConfiguration: {type: Object, required: true}
    },
    mixins: [ RouteQueryParamMixin ],
    data() {
      return {
        dropdownOptions: {
            account: null,
            workgroup: [
              {
                workGroups: []
              }
            ]
        },
        currentAccount: {
          value: null,
          label: null
        },
        currentWorkgroups: [],
        loadingWorkGroup: false,
        multiSelectComponentKey: 0,
        containsTags: this.containsTags || false
      }
    },
    computed: {
      loggedIn(){
        return this.$store.state.user != null
      },
      hasExistingData () {
          return this.$store.state.hasExistingData
      },
      showServiceDeskDashboard() {
        return this.currentAccount.value != null &&
        ( // first check authorization, then whether or not data exists
            this.$store.state.user.roles.includes('ROLE_ADMIN') || 
            this.$store.state.user.adminAccounts.includes(this.currentAccount.value) || 
            this.$store.state.user.dashboards[this.currentAccount.value].includes(1)
          ) &&
          (this.hasExistingData['interactionSegments'] || this.hasExistingData['incidents'])
      },
      showCatStatsDashboard() {
        return this.currentAccount.value != null && 
        ( // first check authorization, then whether or not data exists
            this.$store.state.user.roles.includes('ROLE_ADMIN') || 
            this.$store.state.user.adminAccounts.includes(this.currentAccount.value) || 
            this.$store.state.user.dashboards[this.currentAccount.value].includes(2)
          ) &&
          this.hasExistingData['incidents']
      },
      showProcessMiningDashboard() {
        return this.currentAccount.value != null &&
        ( // first check authorization, then whether or not data exists
            this.$store.state.user.roles.includes('ROLE_ADMIN') || 
            this.$store.state.user.adminAccounts.includes(this.currentAccount.value) ||
            this.$store.state.user.dashboards[this.currentAccount.value].includes(3)
          ) &&
          this.hasExistingData['incidents']
      },
      showWAMalertsDashboard() {
        return this.currentAccount.value != null &&
        ( // first check authorization, then whether or not data exists
            this.$store.state.user.roles.includes('ROLE_ADMIN') || 
            this.$store.state.user.adminAccounts.includes(this.currentAccount.value) || 
            this.$store.state.user.dashboards[this.currentAccount.value].includes(5)
          ) &&
          this.hasExistingData['incidentAlerts']
      },
      showAccountSummary() {
        return this.currentAccount.value != null &&
        ( // first check authorization, then whether or not data exists
            this.$store.state.user.roles.includes('ROLE_ADMIN') || 
            this.$store.state.user.adminAccounts.includes(this.currentAccount.value) || 
            this.$store.state.user.dashboards[this.currentAccount.value].includes(7)
          )
      },
      workgroupLink() {
        let link = `/workgroupCapacity?`;

        if (this.currentAccount) {
          link = link.concat(`accountID=${this.currentAccount.value}&`)
        } 
        if (this.currentWorkgroups.length > 0) {
          link = link.concat(`workgroupID=${this.currentWorkgroupValues}`)
        }
        return link
      },
      homeLogoLink() {
        return `#${this.homeLink}`
      },
      processMiningLink() {
        return `/processMining${this.filterParams}`
      },
      processMiningSubMenuItems() {
        return [
          {
            label: "Overview",
            link: `/processMining/overview${this.filterParams}`
          },
          {
            label: "Process Map",
            link: `/processMining/processMap${this.filterParams}`
          }
        ]
      },
      catMetricsLink() {
        return `/catstats/overview${this.filterParams}`
      },
      catMetricsSubMenuItems() {
        let overview = {
          label: "Overview",
          link: `/catstats/overview${this.filterParams}`
        }
        let cases = {
          label: "Cases",
          link: `/catstats/cases${this.filterParams}`
        }
        let tags = {
          label: "Tags",
          link: `/catstats/tags${this.filterParams}`
        }
        if (this.containsTags) {
          return [ overview, tags, cases ]
        } else {
          return [ overview, cases ]
        }
      },
      capacityLink() {
        return `/serviceDesk${this.filterParams}`
      },
      capacitySubMenuItems() {
        return [
          {
            label: "Historical Coverage",
            link: `/serviceDesk/historicalCoverage${this.filterParams}`
          },
          {
            label: "Weekly Patterns",
            link: `/serviceDesk/weeklyPatterns${this.filterParams}`
          },
          {
            label: "Daily Drill-Down",
            link: `/serviceDesk/dailyDrillDown${this.filterParams}`
          }
        ]
      },
      repetitiveWorkLink() {
        return `/wam${this.filterParams}`
      },
      accountSummaryLink() {
        return `/accountSummary${this.filterParams}`
      },
      homeLink() {
        return `/${this.queryParams}`
      },
      currentAccountValue() {
        return this.currentAccount.value
      },
      currentWorkgroupValues() {
        return this.currentWorkgroups.map(obj => obj.value)
      },
      workGroupsProp() {
        return this.dropdownOptions.workgroup[0].workGroups
      },
      queryParams() {
        let queryParams = "";
        if (this.currentAccount.value != null){
          if(this.currentWorkgroupValues.length > 0 ){
            queryParams = `?accountID=${this.currentAccount.value}&workgroupID=${this.currentWorkgroupValues.join(',')}`
          }
          else {
            queryParams = `?accountID=${this.currentAccount.value}`
          }
        }
        else if (this.currentWorkgroupValues.length > 0 ) {
          queryParams = `?workgroupID=${this.currentWorkgroupValues.join(',')}`
        }
        return queryParams
      },
      filterParams(){
        let filterParams = `${this.queryParams}`
        // add the date range and category params to query params
        if('startDate' in this.$route.query && this.$route.query.startDate != null){
          filterParams = filterParams.concat(`&startDate=${this.$route.query.startDate}`)
        }
        if('endDate' in this.$route.query && this.$route.query.endDate != null){
          filterParams = filterParams.concat(`&endDate=${this.$route.query.endDate}`)
        }
        if('category1' in this.$route.query && this.$route.query.category1 != null){
          filterParams = filterParams.concat(`&category1=${this.$route.query.category1}`)
        }
        if('category2' in this.$route.query && this.$route.query.category2 != null){
          filterParams = filterParams.concat(`&category2=${this.$route.query.category2}`)
        }
        if('category3' in this.$route.query && this.$route.query.category3 != null){
          filterParams = filterParams.concat(`&category3=${this.$route.query.category3}`)
        }
        if('agentId' in this.$route.query && this.$route.query.agentId != null){
          filterParams = filterParams.concat(`&agentId=${this.$route.query.agentId}`)
        }
        if('agentName' in this.$route.query && this.$route.query.agentName != null){
          filterParams = filterParams.concat(`&agentName=${this.$route.query.agentName}`)
        }
        if('tagID' in this.$route.query && this.$route.query.tagID != null){
          filterParams = filterParams.concat(`&tagID=${this.$route.query.tagID}`)
        }
        return filterParams
      },
      getActivePage(){
        let configuration = ["AccessConfiguration", "ExecDashboardConfiguration", "ExecFeedsConfiguration", 
                            "WorkersConfiguration", "WorkgroupsConfiguration", "UnitsAndTimezoneConfiguration"]
        // maps route names with nav item labels
        let routeLabelMap = { 
          "Home": "Home", 
          "ExecSummaryOverview": "Executive Dashboard",
          "ProcessMiningOverview": "Process Mining",
          "ProcessMiningProcessMap": "Process Mining",
          "CatstatsOverview": "Category Metrics",
          "Cases": "Category Metrics",
          "Tags": "Category Metrics",
          "Historical Coverage": "Capacity Management",
          "Weekly Patterns": "Capacity Management",
          "Daily Drilldown": "Capacity Management",
          "WamOverview": "Repetitive Work",
          "AccountSummaryOverview": "Account Summary"
        }
        let routeName = this.$route ? this.$route.name : ""

        if (configuration.includes(routeName)) return "Configuration"
        // if routeName key exists in routeLabelMap then return label value
        if (!(routeLabelMap[routeName] === undefined)) return routeLabelMap[routeName]

        return null

      },
      getLinkClass() {
          if (this.isActivePage) {
            return "c-primary-nav-vertical__text active-page"
          }
          else {
            return "c-primary-nav-vertical__text"
          }
        },
      workGroupVisiblity() {
        if (this.getActivePage == "Executive Dashboard" || this.getActivePage == "Configuration") {
          return "hidden"
        }
        return ""

      },
      workGroupValues() {
        return this.dropdownOptions.workgroup[0].workGroups.filter(obj => obj.selected)
      },
      startDate() {
          // Use the simple flow chart to determine the startDate
          // 1) If the URL contains a start date, use that
          // 2) If not, default to 3 months before  the end date
          // 3) If this is before the minDate, default to the minDate
          var startDate = moment()
          var minDataRange = moment(this.dataRange.minDate, "MM/DD/YYYY")
          var maxDataRange = moment(this.dataRange.maxDate, "MM/DD/YYYY")

          // check for startDate url param
          if (this.getRouteQueryParam('startDate')) {
              // check url startDate is within data range
              startDate = moment(this.getRouteQueryParam('startDate'), "MM/DD/YYYY")
              if (startDate >= minDataRange && startDate <= maxDataRange) {
                  return startDate.format('MM/DD/YYYY')
              }
          } 
          let dateRangeMonths = this.accountConfiguration.dateRangeMonths
          if (moment(this.endDate, "MM/DD/YYYY").subtract(dateRangeMonths, 'months') > minDataRange) {
              return moment(this.endDate, "MM/DD/YYYY").subtract(dateRangeMonths, 'months').format("MM/DD/YYYY")
          } else {
              return minDataRange.format("MM/DD/YYYY")
          }
      },
      endDate() {
          return this.getRouteQueryParam('endDate') || this.dataRange.maxDate
      },   
      /*
      * computed that formats both startDate and endDate
      * keeping it in a single computed allows us to update both params at once
      */
      timeInterval(){
          return {
              start: moment(this.startDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
              end: moment(this.endDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          }
      },         
    },
  methods: {
    clearAccount() {
      this.$store.dispatch('resetPage')
    },
    setActivePage(value) {
      this.activePage = value;
    },
    init() {
      // load accounts and workgroups
      this.getAccounts()
    },
    setCurrentAccount() {
        if (this.accountId) {
            this.currentAccount = this.dropdownOptions.account.find(option => option.value == this.accountId)
        } else if (this.dropdownOptions.account.length > 0) {
          // set first account as default
          this.currentAccount = this.dropdownOptions.account[0]
        }
        
    },
    setCurrentWorkGroup() {
        if (this.workGroupIds) {
          let selectedWGArray = this.workGroupIds.split(",")
            this.dropdownOptions.workgroup[0].workGroups.forEach(option => {
              if(selectedWGArray.includes(option.value.toString())){
                option.selected = true
              }
            })
            this.currentWorkgroups = this.dropdownOptions.workgroup[0].workGroups.filter(option => selectedWGArray.includes(option.value.toString()))
        }
    },
    updateSelectedWorkGroup(values){
      this.currentWorkgroups = values
    },
    getAccounts() {
      axiosRequests
        .get('accounts?size=1000&sort=name')
        .then(({ data }) => {
            this.dropdownOptions.account = data.content.map(
                ({ name, id }) => ({label: name, value: id})
            )
            // execute this after all accounts have been loaded
            this.setCurrentAccount()
        })
        .catch(e => console.log(e))
      
    },
    getWorkGroups() {
      this.loadingWorkGroup = true
      let accountString = this.currentAccountValue != null ? `?accountId=${this.currentAccountValue}` : ''
      axiosRequests
      .get(`workGroups${accountString}`)
      .then(({ data }) =>{
          this.dropdownOptions.workgroup = [
            {
              workGroups: data.map(
                  ({ name, id }) => ({key: name ? name : '[Blank Workgroup]', value: id, selected: false})
              )
            }
          ]
          this.setCurrentWorkGroup()

      })
      .then(() => {
        this.loadingWorkGroup = false
      })
      .catch(e => console.log(e))
    },
    selectFunctionalArea(newWkGrpIds) {
      let oldWkGrpIds = this.currentWorkgroups.map(wg => wg.value)
      // update the 'selected' boolean value of the old selectedWorkGroups to false before setting the new selectedWorkGroups to true
      this.dropdownOptions.workgroup[0].workGroups.forEach(option => {
              if(oldWkGrpIds.includes(option.value)){
                option.selected = false
              }
              if(newWkGrpIds.includes(option.value)){
                option.selected = true
              }
            })

      this.currentWorkgroups = this.dropdownOptions.workgroup[0].workGroups.filter(option => newWkGrpIds.includes(option.value))
      // force multi-select component to re-render with updated dropdown options selections
      this.multiSelectComponentKey+=1
    },
    checkForTags() {
      // NOTE: the checkForTags endpt expects accountId & workGroupId instead of accountID & workGroupID
      let params = {
        accountId: this.currentAccountValue,
        workGroupId: this.currentWorkgroupValues.join(','),
        startDate: this.timeInterval['start'],
        endDate: this.timeInterval['end'],
        agentId: this.$route.query.agentId,
        category1: this.$route.query.category1,
        category2: this.$route.query.category2,
        category3: this.$route.query.category3,
        tags: this.$route.query.tagID,
        timeZone : this.accountConfiguration.timeZone,
        inScope: this.$route.query.inScope
      }

      axiosRequests.get(
          'catstats/checkForTags',
          {
              "params": params
          }
      )
      .then(({ data }) => {
          this.containsTags = data
      })
      .catch(error => console.log(error))
    },

  },
  mounted() {
    this.init() 
  },
  watch: {
    currentAccountValue:{
      handler() {
        if (this.currentAccount.value == null){
          // set workgroup to also be null (for dropdown) and send to index page of tk
          // user must have an account selected (for now)
          this.currentWorkgroups = []
          this.$router.push({path: "/", query: {}})
        }
        else{
          this.getWorkGroups()
          let models = ['incidents', 'interactionSegments','incidentAlerts', 'execDashboard']
          let account = this.currentAccount
          let workgroups = this.currentWorkgroups.length > 0 ? this.currentWorkgroups.map(obj => obj.value).join(',') : null
          this.$store.dispatch('determineExistingData', { models, account, workgroups })
          
          this.setRouteQueryParam({accountID: this.currentAccount.value})
        }
      }
    },
    currentWorkgroupValues:{
      handler() {
        this.setRouteQueryParam({workgroupID: this.currentWorkgroups.map(obj => obj.value).join(',')})
        if (this.currentAccount.value != null){
          let models = ['incidents', 'interactionSegments','incidentAlerts', 'execDashboard']
          let account = this.currentAccount
          let workgroups = this.currentWorkgroups.length > 0 ? this.currentWorkgroups.map(obj => obj.value).join(',') : null
          this.$store.dispatch('determineExistingData', { models, account, workgroups })
        }
      }
    },
    accountId:{
      deep: true,
      handler() {
        if (this.currentAccount.value != this.accountId && this.dropdownOptions.account != null){
          // if accountId is null, just pick the first item from the list. basically never let accountId actually be null
          let currAccountList = this.accountId ?
            this.dropdownOptions.account.filter(acc => Number(acc.value) == Number(this.accountId)) :
            this.dropdownOptions.account.filter(acc => Number(acc.value) == Number(this.currentAccount.value))
          if (currAccountList && currAccountList.length > 0){
            this.currentAccount = currAccountList[0]
            this.setRouteQueryParam({accountID: this.currentAccount.value})
          } 
        }
      }
    },
    workGroupIds:{
      deep: true,
      handler() {
        if (this.currentWorkgroupValues.toString() != (this.workGroupIds ? this.workGroupIds : "") && this.dropdownOptions.workgroup != null){
          let currWorkgroupList = this.workGroupIds ? this.dropdownOptions.workgroup[0].workGroups.filter(acc => this.workGroupIds.split(",").includes(acc.value.toString())) : []
          if (currWorkgroupList && currWorkgroupList.length > 0){
            this.currentWorkgroups = currWorkgroupList
          } 
        }
      }
    },
    '$route' (to, from){
      if(to.name=="Home" && !Object.keys(to.query).includes('accountId')){
        this.init()
      }
    },
    queryParams:{
        deep: true,
        handler(){
            this.checkForTags()
        }
    }

  }
}
</script>

<style scoped>

.l-page-layout__secondary{
  overflow:visible;
}

.c-primary-nav-vertical{
  position:relative;
}
.c-primary-nav-vertical__item{
  margin:0rem;
}

.logo-text{
  font-size:20px;
  padding-left:10px;
} 

.logo-container{
  display:flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ECEFF1;
  font-weight: bold;
  margin: 30px 0px 60px 20px;
} 

.c-logo-vertical{
  margin:0;
  width:28px;
  height:28px;
  min-width:0rem;
}

.c-logo_img{
  width:28px;
  height:28px;
}

.dropdown-nav{
  padding: 0px 39px 33px 23px;
}

.dropdown-item{
  padding-bottom:14px;
}

.c-icon{
  fill: #B0BEC5;;
  margin-right:15px;
} 

.c-input--select{
  background-color: rgba(226, 241, 248, 0.4);
  color: #ECEFF1;
} 

.c-input--select option{
  background-color: rgba(109, 116, 120);
  color: #ECEFF1;
}

.footer{
  position: absolute;
  bottom: 20px;
  line-height: 13pt;
}

.footer-label{
  font-size: .8em;
}

.c-header-vertical__inner {
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    min-height: 100vh; 
}


</style>

