<template>
    <div>
        <div class="paginated-table-container" v-if="searchEnabled">
            <div v-if='tableTitle.length > 0' class="paginated-table-title">{{tableTitle}}</div>
            <InfoTooltip v-if='infoText.length > 0'
                class="paginated-table-info-tip"
                :infoText='infoText' 
            />
            <div :class="paddingRequired ? 'search-filter-padded' : 'search-filter'">
                <DatatableSearchFilter
                    :placeholderText="searchFilterPlaceHolderText"
                    @emitFunc="setSearchText"
                />
            </div>
        </div>
        <div v-if='loading' class="loader-container-cards">
            <div class='loader-small'></div>
        </div>
        <div v-else-if="allData != null && allData.length > 0">
            <ClientPaginatedTable
                :columnNames="columnNames"
                :sortableCells="sortableCells"
                :tableData="tableData"
                :cells="cells"
                :cellTypes="cellTypes"
                :widths="widths"
                :useRowEnter="useRowEnter"
                :useRowLeave="useRowLeave"
                :sortState="sortState"
                :abbreviatedCells="abbreviatedCells"
                :centerAlignedCells="centerAlignedCells"
                :centerAlignedColumns="centerAlignedColumns"
                :clickableCells="clickableCells"
                :linkedCells="linkedCells"
                :makeLink="makeLink"
                :clickableIconImages="clickableIconImages"
                :showClickableCellText="showClickableCellText"
                :totalRow="totalRow"
                @rowEnterEmit="rowEnterEmit"
                @rowLeaveEmit="rowLeaveEmit"
                @sort="sortData"
                @onCellClick="onCellClick"
            />
            <PageNumberSelect
                :totalPages="totalPages"
                :offset="offset"
                :numElements="numElements"
                :pageNumber="pageNumber"
                :totalElements="totalElements"
                @emitFunc="setPageNumber"
            />
        </div>
        <div v-else class="no-data-container">
            <span>There's no data here!</span>
        </div>
    </div>
</template>

<script>
import DatatableSearchFilter from "@Common/DatatableSearchFilter.vue"
import ClientPaginatedTable from "@Common/ClientPaginatedTable.vue"
import PageNumberSelect from "@Common/PageNumberSelect.vue"
import { axiosRequests } from '@/main';
import InfoTooltip from '@Common/InfoTooltip.vue'

export default {
    name: "ClientPaginatedTableContainer",
    components: {DatatableSearchFilter, ClientPaginatedTable, PageNumberSelect, InfoTooltip},
    props:{
        // this is a json object of the form { cellName : maxCharacterWidth }
        abbreviatedCells:{
            type: Object,
            required: false,
            default: () => {}
        },
        apiPath:{
            type:String,
            required: false,
            default: ""
        },
        columnNames:{
            type: Array
        },
        cells:{
            type: Array
        },
        cellTypes:{
            type: Array
        },
        sortableCells:{
            type: Array,
            required: false
        },
        defaultSort:{
            type: String,
            required: false
        },
        widths:{
            type: Array
        },
        queryParams:{
            type: Object,
            required: false,
            default: () => {}
        },
        watchParams:{
            type: Object,
            required: false,
            default: () => {}
        },
        responseTransform:{
            type: Function,
            required: false,
            default: (responseData) => {return responseData}
        },
        useRowEnter:{
            type: Boolean,
            default: false
        },
        useRowLeave:{
            type: Boolean,
            default: false
        },
        centerAlignedCells:{
            type: Array,
            required: false,
            default: () => []
        },
        centerAlignedColumns:{
            type: Array,
            required: false,
            default: () => []
        },
        clickableCells:{
            type: Array,
            required: false,
            default: () => []
        },
        linkedCells:{
            type: Array,
            required: false,
            default: () => []
        },
        useFetchData:{
            type: Boolean,
            required: false,
            default: true
        },
        tableDataProp:{
            type: Array,
            required: false,
            default: () => []
        },
        filterBySearchText:{
            type: Function,
            required: false,
            default: (allData, searchText) => {
                console.log("filterBySearchText cb fn not provided... returning allData without filtering.")
                return allData
            }
        },
        tableTitle:{
            type: String,
            required: false,
            default: ""
        },
        searchFilterPlaceHolderText:{
            type: String,
            required: false,
            default: "Enter search term"
        },
        searchEnabled:{
            type: Boolean,
            default: true
        },
        initialPageSize: {
            type: Number,
            default: 7
        },
        infoText: {
            type: String,
            required: false,
            default: ""
        },
        makeLink:{
            type: Function,
            required: false,
            default: (row) => {return ""}
        },
        // this is a json object of the form { cellName : iconFileName.svg }
        // passes directly to ClientPaginatedTable component which has a default value pointed to the @Images/icons/ directory
        clickableIconImages: {
            type: Object,
            required: false
        },
        includeTotalRow: {
            type: Boolean,
            default: false
        },
        // function that should aggregate the totals & formats them same way as the responseTranform
        // should return an object with properties matching the tableConfig's tableCells array (aka the 'cells' prop that's passed into this component)
        totalRowTransform:{
            type: Function,
            required: false,
            default: () => { return null }
        },
        showClickableCellText:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return{
            allData: null,
            totalRow: {},
            pageNumber: null,
            loading: false,
            sortState: {},
            searchData: null,
            pageSize: this.initialPageSize,
            searchText: String() // instantiating as String obj vs "" or '' allows the String.includes() fn to properly search for quoted text substring & subtrings containing apostrophes
        }
    },
    methods: {
        setSearchText(text){
            this.searchText = text
        },
        fetchData(){
            if(this.useFetchData){
                this.loading = true
                axiosRequests
                    .get(this.apiPath, {params: this.queryParams})
                    .then(response => {
                        this.allData = response.data
                        this.sortData()
                        this.pageNumber = 0
                        this.loading = false
                    })
                    .catch(error => console.log(error))
            }
            else {
                // .slice() basically makes a copy of the array otherwise allData is just a reference to the original
                this.allData = this.tableDataProp.slice() // slice em and dice em baby
                this.sortData()
                this.pageNumber = 0
            }
        },
        sortData(sort=this.defaultSort, type="Number") {
           if (sort != undefined) {
                const [attr, dir] = sort.split(',')
                const sign = dir == 'asc' ? 1 : -1 
                if (type == "String") {
                    // checks if value is null and places it at end of sorted array if true
                    this.allData.sort((a, b) => a[attr] ? (a[attr].localeCompare(b[attr], navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}) * sign) : 1)
                }
                else {
                     this.allData.sort((a, b) => (a[attr] - b[attr]) * sign)
                }
                this.sortState = {[attr]: sign}
                this.pageNumber = 0
            }
        },
        setPageNumber(page){
            this.pageNumber = page
        },
        rowEnterEmit(row){
            if(this.useRowEnter) this.$emit("rowEnterEmit", row)
        },
        rowLeaveEmit(row){
            if(this.useRowLeave) this.$emit("rowLeaveEmit", row)
        },
        noData(){
            this.loading = true
            this.allData = []
            this.loading = false
        },
        onCellClick(row, cell){
            this.$emit("onCellClick", row, cell)
        },
        filteredData() {
            if(this.allData == null){
                return null
            } else{
                // filters data by search text
                let filterBySearchTextData = this.filterBySearchText(this.allData, this.searchText)
                // update searchData for PageNumberSelect related computed fns (numElements, totalPages, totalElements)
                this.searchData = filterBySearchTextData
                // returns an array of transformed elements for a subset of filtered data based on page offset
                return this.responseTransform(filterBySearchTextData.slice(this.offset, this.offset + this.pageSize))
            }
        }
    },
    computed: {
        numElements() {
            if(this.allData == null) return null
            if(this.searchText.length > 0) return Math.min(this.searchData.length - (this.pageNumber * this.pageSize), this.pageSize)
            return Math.min(this.allData.length - (this.pageNumber * this.pageSize), this.pageSize)
        },
        tableData() {
            if(this.allData == null){ return null } 
            if(this.searchText.length > 0) { return this.filteredData() }
            // update totalRow
            if (this.includeTotalRow) {
                this.totalRow = this.totalRowTransform(this.allData)
            }
            return this.responseTransform(this.allData.slice(this.offset, this.offset + this.pageSize))
        },
        offset(){
            if(this.pageNumber == null ){
                return 0
            }
            else{
                return (this.pageNumber * this.pageSize)
            }
        },
        totalPages(){
            if(this.allData == null) return null
            if(this.searchText.length > 0) return Math.ceil(this.searchData.length/this.pageSize)
            return Math.ceil(this.allData.length/this.pageSize)
        } ,
        totalElements(){
            if(this.allData == null) return null
            if(this.searchText.length > 0) return this.searchData.length
            return this.allData.length
        },
        paddingRequired(){
            return this.infoText.length > 0 || this.tableTitle.length > 0
        }
    },
    mounted() {
        this.fetchData()
        
    },
    watch:{
        watchParams:{
            deep: true,
            handler(){
                if(this.useFetchData){
                    this.fetchData()                    
                }
            }
        },
        queryParams:{
            deep: true,
            handler(){
                this.fetchData()
            }
        },
        defaultSort:{
            deep: true,
            handler(){
                this.sortData()
            }
        },
        searchText:{
            deep: true,
            handler() {
                if( this.pageNumber != 0) { this.pageNumber = 0 }
                // computed fn tableData calls the filteredData method when searchText string is not empty
            }
        },
        tableDataProp:{
            deep: true,
            immediate: true,
            handler() {
                if(!this.useFetchData){
                    this.fetchData()
                }
            }
        }
    }
}
</script>

<style scoped>
.no-data-container{
    font-size: 14px;
    color: #808E95;
    display:flex;
    justify-content: center;
    padding: 20px;
    padding-top: 60px;
}
.search-filter-padded{
    padding-left: 20px;
    padding-bottom:10px;
    width: 15rem;
}
.search-filter{
    padding-bottom:10px;
    width: 15rem;
}
.paginated-table-container{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.paginated-table-title {
    font-weight: bold;
    font-size: 20px;
    padding-bottom:10px;
    padding-right: 5px;
}
.paginated-table-info-tip {
    padding-bottom: 10px;
}
</style>
