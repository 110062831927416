<template>
    <div>
        <error-message />
        <div v-if="showSideNav" class="side-nav">
            <side-navigation 
                :accountId="accountId"
                :workGroupIds="workGroupIds" 
                :dataRange="dataRange"
                :accountConfiguration="accountConfiguration"
            />
        </div>
        <div v-if='systemLoading && loggedIn' class="loader-container-page">
                <div class='loader loader-page'></div>
        </div>
        <div v-else :class="loggedIn ? 'router-view' : 'login-view'">
            <router-view 
                :dataRange="dataRangeAdjusted"
                :accountConfiguration="accountConfiguration" 
            />
            <app-footer
                :dataRange="dataRange"
                :accountConfiguration="accountConfiguration"
            />
        </div>
    </div>
</template>

<script>
import ErrorMessage from '@Common/ErrorMessage.vue'
import SideNavigation from '@Common/SideNavigation.vue'
import Footer from '@Common/Footer.vue'
import RouteQueryParamMixin from '@Mixins/RouteQueryParamMixin.vue'
import axios from 'axios'
import { axiosRequests } from '@/main.js'
import moment from 'moment-timezone'

// set default timezone to UTC so that times displayed are consistent with times in api responses
// which we do not want to transform, we assume that timezones adjustments are already made
moment.tz.setDefault('UTC')

export default {
    mixins: [ RouteQueryParamMixin ],
    components: {
        'side-navigation': SideNavigation,
        'app-footer': Footer,
        'error-message': ErrorMessage
    },
    created () {
        this.fetchAADParams()
        this.fetchJWTExpiration()
        this.fetchDsDownloadEnabled()
        const token = localStorage.getItem("token")
        axiosRequests.defaults.headers.authorization = token
        if (token) {
            axiosRequests.get()
                .then(resp => {
                    if (resp.status == 200) {
                        this.$store.dispatch('loginFromToken', { token })
                    }
                })
                .catch(() => {
                        this.$store.dispatch('logout')
                        this.$store.dispatch('setSystemMessage', {message: "You have been logged out. Please enter your credentials."})
                    }
                )
            
        } else {
            // TODO: Use webpack to strip this code block in production
            axiosRequests.get()
                .then(resp => {
                    // If authorization header is empty and resp is 200, security-off profile is active
                    if (resp.status == 200) {
                        const token = "STUB-JWT.eyJzdWIiOiJhZG1pbiIsImF1dGhvcml0aWVzIjoiUk9MRV9BRE1JTixSRUFELENSRUFURSxERUxFVEUsVVBEQVRFIn0=.UNSIGNED"
                        localStorage.setItem("token", token)
                        this.$store.dispatch('loginFromToken', { token })
                    }
                }
            )
                .catch(() => this.$store.dispatch('logout'))
        }
        
        if (this.accountId) {
            this.getDataRange()
            this.getAccountConfiguration()
        }
    },
    computed: {
        showSideNav() {
            return this.loggedIn && this.$route.path != "/login"
        },
        loggedIn(){
          return this.$store.state.user!= null
        },
        configLoading() {
            return this.$store.state.loading.accountConfiguration
        },
        dataRangeLoading() {
            return this.$store.state.loading.dataRange
        },
        existingDataLoading() {
            return this.$store.state.loading.dataRange
        },
        // check if the state is explicitly loading or if acountId has not been set yet
        systemLoading() {
            return this.dataRangeLoading || this.existingDataLoading || (this.accountId && this.configLoading)
        },
        appVersion() {
          return process.env.APP_VERSION
        },
        accountId() {
            let id = this.getRouteQueryParam('accountID')
            return id
        },
        workGroupIds() {
            let workGroupIds = this.getRouteQueryParam('workgroupID')
            if(workGroupIds && workGroupIds.length > 0) {
                return workGroupIds
            }
            return null
        },
        dataRange() {
            return { 
                minDate: this.$store.state.minDate,
                maxDate: this.$store.state.maxDate
            }
        },
        dataRangeAdjusted() {
            if (this.accountConfiguration.dataRangeDisabled == false) {
                return this.dataRange
            } else {
                return {
                    minDate: "01/01/1970",
                    maxDate: moment().format("MM/DD/YYYY")
                }
            }
        },
        accountConfiguration() {
            let accountTimeZoneOrDefault = this.$store.state.accountWkgrpConfigs.timeZone ? this.$store.state.accountWkgrpConfigs.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone 
            return { 
                slaEnabled: this.$store.state.slaEnabled,
                timeZone: accountTimeZoneOrDefault,
                accountId: this.$store.state.accountWkgrpConfigs.account.id,
                accountName: this.$store.state.accountWkgrpConfigs.account.name,
                dataRangeDisabled: this.$store.state.dataRangeDisabled,
                processMapType: this.$store.state.accountWkgrpConfigs.processMapType,
                dateRangeMonths: this.$store.state.accountWkgrpConfigs.dateRangeMonths
            }
        }
    },
    methods: {
        getDataRange() {
            this.$store.dispatch('determineDataRange', { accountId: this.accountId })
        },
        getAccountConfiguration() {
            this.$store.dispatch('getAccountConfiguration', { accountId: this.accountId })
        },
        fetchAADParams() {
            axios.get(`${process.env.BASE_URL}/metadata/sso`)
                .then(response => {
                    this.$store.dispatch('setAADVars', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        fetchJWTExpiration(){
            axios.get(`${process.env.BASE_URL}/metadata/jwtExpiration`)
                .then(response => {
                    this.$store.dispatch('setJWTExpiration', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        fetchDsDownloadEnabled(){
            axios.get(`${process.env.BASE_URL}/metadata/dsDownloadConfig`)
                .then(response => {
                    this.$store.dispatch('setDsDownloadEnabled', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    watch: {
        accountId() {
            // avoid making calls when accountId is undefined
            if(this.accountId){
                this.getDataRange()
                this.getAccountConfiguration()
            }
        }
    }
}
</script>

<style scoped>

.side-nav{
    width:17rem;
}
.router-view{
    margin-left:17rem;
    background:#FFFFFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}
.login-view{
    margin-left:0rem;
}

html{
  min-height: 100%;
}
</style>

// Load CSS Blocks
<style src="@Style/ckm-style/stylesheets/index.css"></style>
<style src="@Style/ckm-style/stylesheets/grid.css"></style>

// Load CKM Theme files
<style src="@Style/ckm-style/stylesheets/main.css"></style>
<style src="@Style/ckm-style/stylesheets/header.css"></style>
<style src="@Style/ckm-style/stylesheets/sideMenu.css"></style>
<style src="@Style/ckm-style/stylesheets/visualizationContainer.css"></style>
<style src="@Style/ckm-style/stylesheets/typography.css"></style>
<style src="@Style/ckm-style/stylesheets/description.css"></style>
<style src="@Style/ckm-style/stylesheets/navbar.css"></style>
<style src="@Style/ckm-style/stylesheets/animation.css"></style>
<style src="@Style/ckm-style/stylesheets/override.css"></style>
<style src="@Style/ckm-style/stylesheets/v-tooltip.css"></style>
