<template>
  <div :id="sideNavItemWrapperId" :ref="sideNavItemWrapperId" class="nav-item-wrapper">
    <div :id="sideNavItemId" :ref="sideNavItemId" v-bind:class="getClass" @click="routeToLink" @mouseenter="setSubMenuTop">
         <router-link :to="link">
             <button 
                 class="nav-item__button c-primary-nav-vertical__link c-primary-nav-vertical__link--has-children primary-background-hover primary-background-text-color-hover primary-background-focus primary-background-text-color-focus" 
                 role="button">
 
                 <svg class="c-icon" aria-hidden="true">
                     <use :href="icon"></use> 
                 </svg> 
 
                 <span :class="getLinkClass">
                 {{ label }}
                 </span>
 
             </button>
           </router-link>
     </div>
      <span
        v-if="subMenuItems"
        class="sub-menu"
        :id="subMenuId"
        :ref="subMenuId"
      >
       <div 
        v-for="subItem in subMenuItems"
        class="c-primary-nav-vertical__subitem"
        v-bind:key="`${label} ${subItem.label}`"
       >
         <router-link :to="subItem.link" @click="routeToLink">
           <button
             class="nav-item__button c-primary-nav-vertical__link c-primary-nav-vertical__link--has-children primary-background-hover primary-background-text-color-hover primary-background-focus primary-background-text-color-focus" 
             role="button"
           >
             <span class="c-primary-nav-vertical__text">
               {{ subItem.label }}
             </span>
           </button>
         </router-link>
       </div>
      </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SideNavigationItem',
    props: ['label', 'link', 'icon', 'activePage', 'subMenuItems'], 
    data(){
        return {
            localDropDown: this.$props.dropDown,
        }
    },

    computed:{
        ...mapGetters([
           'isAdmin'
        ]),
         isMenuShown() {
          return this.label === this.dropDown
        },
        isActivePage() {
          return this.label === this.activePage
        },
        getClass() {
          if (this.isMenuShown) {
           return "c-primary-nav-vertical__item inline-block is-active"
          } else {
            return "c-primary-nav-vertical__item inline-block"
            }
        },
        getLinkClass() {
          if (this.isActivePage) {
            return "c-primary-nav-vertical__text active-page"
          }
          else {
            return "c-primary-nav-vertical__text"
          }
        },
        sideNavItemId() {
          if (this.label.includes(" >")) {
            return this.label.replace(" >", "")
          } else return this.label
        },
        subMenuId() {
          return `${this.label} submenu`
        },
        sideNavItemWrapperId() {
          if (this.label.includes(" >")) {
            return `${this.label.replace(" >", "")} wrapper`
          } else return `${this.label} wrapper`
        },
        sideNavItemIndex() {
          let navItemWrapper = this.$refs[this.sideNavItemWrapperId]
          let navItemCollection = Array.from(navItemWrapper.parentElement.children)
          return navItemCollection.indexOf(navItemWrapper)
        },
        sideNavItemHeight() {
          return this.$refs[this.sideNavItemWrapperId].getBoundingClientRect().height
        }
      },
      methods: {
        routeToLink () {
          this.setActivePage()
        },
        setActivePage() {
          this.$emit('setActivePage', this.label)
        },
        setSubMenuTop(e) {
          if (this.subMenuItems) {
            let sm = this.$refs[this.subMenuId]
            sm.style.top = `${this.sideNavItemIndex * this.sideNavItemHeight}px`
          }
        }
    }
}
</script>

<style scoped>
    /* Original */
    /* font-size: 16px; */
    .nav-item__button {
      background-color: #212121;
      border: none;
    } 

    .button:focus{
      outline:0px;
    }  

    .c-primary-nav-vertical__text.active-page {
      font-family: "Nunito Bold", Helvetica, sans-serif;
      color: #FFFFFF;
    }  

    .c-icon{
      fill: #B0BEC5;;
      margin-right:15px;
      width: 23px;
      height: 23px;
    } 

    .c-primary-nav-vertical__item.is-active .c-icon{
      fill: #ECEFF1;
    }  

    .c-primary-nav-vertical__link{
        padding: 1rem 16px 1rem 23px;
        width:100%;
    }
    
    .c-primary-nav-vertical__link:focus, .c-primary-nav-vertical__link:hover {
      font-family: "Nunito Bold", Helvetica, sans-serif;
    } 

    .c-primary-nav-vertical__link:focus .c-icon, .c-primary-nav-vertical__link:hover .c-icon{
      fill: #ECEFF1;
    }

    .inline-block {
      display: inline-block;
      width: 100%;
    } 

    .sub-menu {
      display: none;
      position: absolute;
      /* right: -18vh; */
      right: -68%;
      width: 185px;
    }

    .nav-item-wrapper:hover .sub-menu {
      display: inline-block;
    }

</style>
