<template>
    <div>
        <input 
            type="text"
            :placeholder="placeholderText"
            ref="input"
            v-model="searchText"
            class="c-input--text"
        />
    </div>
</template>

<script>
export default {
    name: "DatatableSearchFilter",
    props: {
        placeholderText:{
            type: String
        }
    },
    data(){
        return{
            searchText: ""
        }
    },
    watch:{
        searchText:{
            deep: true,
            handler(){
                this.$emit('emitFunc', this.searchText)
            }
        }
    }
}
</script>


