<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    {{ title }}
                </div>
                <div class="modal-subheader" v-html="message">
                </div>
                <div class="button-container">
                    <div
                        class="go-back button-base primary-border-button"
                        @click="closeModal"
                    >
                        {{ cancelBtnMsg }}
                    </div>
                    <div
                        class="save button-base primary-background primary-background-text-color"
                        @click="confirmSave"
                    >
                        {{ confirmBtnMsg }}
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "ConfirmSaveModal", 
    props: {
        propTitle: { type: String },
        propMessage: { type: String },
        propConfirmBtnMsg: { type: String },
        propCancelBtnMsg: { type: String },
    },
    data() {
        return {
            defaultTitle: 'Are you sure you want to save?',
            defaultMessage: 'This action cannot be undone',
            defaultConfirmButtonMsg: 'SAVE',
            defaultCancelButtonMsg: 'GO BACK',
        }
    },
    computed: {
        title() {
            return this.propTitle ?? this.defaultTitle
        },
        message() {
            return this.propMessage ?? this.defaultMessage
        },
        confirmBtnMsg() {
            return this.propConfirmBtnMsg ?? this.defaultConfirmButtonMsg
        },
        cancelBtnMsg() {
            return this.propCancelBtnMsg ?? this.defaultCancelButtonMsg
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal")
        },
        confirmSave() {
            this.$emit("confirmSave")
        }
    }
      
  }
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 17rem;
    width: 86vw;
    height: 100%;
    background-color: rgba(176, 190, 197, 0.5);
}
.modal-container {
    max-width: 600px;
    max-height: 95vh;
    margin: 1.75rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    box-shadow: -4px 4px 4px rgba(49, 48, 48, 0.1);
    border-radius: 0.6rem;
    display:flex;
    flex-direction: column;
    font-size:12px;
}
.modal-header {
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
}
.modal-subheader{
    font-size: 12px;
}
.button-container{
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}
.button-base{
    width: 18%;
    border-radius: 3px; 
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
}
.button-base:hover{
    cursor: pointer;
}
.go-back{
    box-sizing: border-box;
    margin-right: 5px;
}
.save{
    margin-left: 5px;
}
.modal-wrapper{
    margin-top: 200px;
}

.disc-list{
    list-style: disc
}
</style>

