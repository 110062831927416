const AccessConfiguration = resolve => {
    require.ensure(['./components/pages/configuration/access/AccessConfiguration.vue'], () => {
        resolve(require('./components/pages/configuration/access/AccessConfiguration.vue'), 
        'access')
    })
}

const Catstats = resolve => {
    require.ensure(['./components/pages/catstats/Catstats.vue'], () => {
        resolve(require('./components/pages/catstats/Catstats.vue'))
    }, 'catstats')
}

const CapacityMetricsHistoricalCoverage = resolve => {
    require.ensure(['./components/pages/serviceDesk/CapacityMetricsHistoricalCoverage.vue'], () => {
        resolve(require('./components/pages/serviceDesk/CapacityMetricsHistoricalCoverage.vue'))
    }, 'capacityMetrics')
}

const CapacityMetricsWeeklyPatterns = resolve => {
    require.ensure(['./components/pages/serviceDesk/CapacityMetricsWeeklyPatterns.vue'], () => {
        resolve(require('./components/pages/serviceDesk/CapacityMetricsWeeklyPatterns.vue'))
    }, 'capacityMetrics')
}

const CapacityMetricsDailyDrilldown = resolve => {
    require.ensure(['./components/pages/serviceDesk/CapacityMetricsDailyDrilldown.vue'], () => {
        resolve(require('./components/pages/serviceDesk/CapacityMetricsDailyDrilldown.vue'))
    }, 'capacityMetrics')
}

const CatstatsOverview = resolve => {
    require.ensure(['./components/pages/catstats/CatstatsOverview.vue'], () => {
        resolve(require('./components/pages/catstats/CatstatsOverview.vue'))
    }, 'catstats')
}

const CatstatsTags = resolve => {
    require.ensure(['./components/pages/catstats/CatstatsTags.vue'], () => {
        resolve(require('./components/pages/catstats/CatstatsTags.vue'))
    }, 'catstats')
}

const CatstatsCases = resolve => {
    require.ensure(['./components/pages/catstats/CatstatsCases.vue'], () => {
        resolve(require('./components/pages/catstats/CatstatsCases.vue'))
    }, 'catstats')
}

const Configuration = resolve => {
    require.ensure(['./components/pages/configuration/Configuration.vue'], () => {
        resolve(require('./components/pages/configuration/Configuration.vue'), 
        'configuration')
    })
}

const ExecFeedsConfiguraion = resolve => {
    require.ensure(['./components/pages/configuration/execFeeds/ExecFeedsConfiguration.vue'], () => {
        resolve(require('./components/pages/configuration/execFeeds/ExecFeedsConfiguration.vue'), 
        'execFeedsConfiguraion')
    })
}

const Forbidden = resolve => {
    require.ensure(['./components/Forbidden.vue'], () => {
        resolve(require('./components/Forbidden.vue'))
    }, 'Forbidden')
}

const Forecast = resolve => {
    require.ensure(['./components/pages/serviceDesk/Forecast.vue'], () => {
        resolve(require('./components/pages/serviceDesk/Forecast.vue'))
    }, 'forecast')
}

const LiveStats = resolve => {
    require.ensure(['./components/pages/serviceDesk/LiveStats.vue'], () => {
        resolve(require('./components/pages/serviceDesk/LiveStats.vue'))
    }, 'liveStats')
}

const Login = resolve => {
    require.ensure(['./components/Login.vue'], () => {
        resolve(require('./components/Login.vue'))
    }, 'Login')
}

const NavigationPage = resolve => {
    require.ensure(['./components/NavigationPage.vue'], () => {
        resolve(require('./components/NavigationPage.vue'))
    }, 'navigationPage');
}

const UnitsAndTimezoneConfiguration = resolve => {
    require.ensure(['./components/pages/configuration/unitsAndTimezone/UnitsAndTimezoneConfiguration.vue'], () => {
        resolve(require('./components/pages/configuration/unitsAndTimezone/UnitsAndTimezoneConfiguration.vue'), 
        'unitsAndTimezoneConfiguration')
    })
}

const PageNotFound = resolve => {
    require.ensure(['./components/PageNotFound.vue'], () => {
        resolve(require('./components/PageNotFound.vue'))
    }, 'pageNotFound')
}

const ProcessMining = resolve => {
    require.ensure(['./components/pages/processMining/ProcessMining.vue'], () => {
        resolve(require('./components/pages/processMining/ProcessMining.vue'))
    }, 'processMining')
}

const ProcessMiningOverview = resolve => {
    require.ensure(['./components/pages/processMining/ProcessMiningOverview.vue'], () => {
        resolve(require('./components/pages/processMining/ProcessMiningOverview.vue'))
    }, 'processMining')
}

const ProcessMiningProcessMap = resolve => {
    require.ensure(['./components/pages/processMining/ProcessMiningProcessMap.vue'], () => {
        resolve(require('./components/pages/processMining/ProcessMiningProcessMap.vue'))
    }, 'processMining')
}

const WorkersConfiguration = resolve => {
    require.ensure(['./components/pages/configuration/workers/WorkersConfiguration.vue'], () => {
        resolve(require('./components/pages/configuration/workers/WorkersConfiguration.vue'), 
        'WorkersConfiguration')
    })
}

const Scheduler = resolve => {
    require.ensure(['./components/pages/serviceDesk/Scheduler.vue'], () => {
        resolve(require('./components/pages/serviceDesk/Scheduler.vue'))
    }, 'scheduler')
}

const ServiceDesk = resolve => {
    require.ensure(['./components/pages/serviceDesk/ServiceDesk.vue'], () => {
        resolve(require('./components/pages/serviceDesk/ServiceDesk.vue'))
    }, 'serviceDesk')
}

const SideMenu = resolve => {
    require.ensure(['./components/common/SideMenu.vue'], () => {
        resolve(require('./components/common/SideMenu.vue'))
    }, 'sideMenu')
}

const Wam = resolve => {
    require.ensure(['./components/pages/wam/Wam.vue'], () => {
        resolve(require('./components/pages/wam/Wam.vue'))
    }, 'wam')
}

const WamOverview = resolve => {
    require.ensure(['./components/pages/wam/WamOverview.vue'], () => {
        resolve(require('./components/pages/wam/WamOverview.vue'))
    }, 'wam')
}

const WorkgroupsConfiguration = resolve => {
    require.ensure(['./components/pages/configuration/workgroups/WorkgroupsConfiguration.vue'], () => {
        resolve(require('./components/pages/configuration/workgroups/WorkgroupsConfiguration.vue'), 
        'workgroupsConfiguration')
    })
}

const AccountSummary = resolve => {
    require.ensure(['./components/pages/accountSummary/AccountSummary.vue'], () => {
        resolve(require('./components/pages/accountSummary/AccountSummary.vue'))
    }, 'accountSummary')
}

const AccountSummaryOverview = resolve => {
    require.ensure(['./components/pages/accountSummary/AccountSummaryOverview.vue'], () => {
        resolve(require('./components/pages/accountSummary/AccountSummaryOverview.vue'))
    }, 'accountSummary')
}

const NotificationsConfiguraion = resolve => {
    require.ensure(['./components/pages/configuration/notifications/NotificationsConfiguration.vue'], () => {
        resolve(require('./components/pages/configuration/notifications/NotificationsConfiguration.vue'), 
        'notifications')
    })
}

const CustomMetricsConfiguration = resolve => {
    require.ensure(['./components/pages/configuration/customMetric/CustomMetricsConfiguration.vue'], () => {
        resolve(require('./components/pages/configuration/customMetric/CustomMetricsConfiguration.vue'), 
        'customMetrics')
    })
}

const UserConfiguration = resolve => {
    require.ensure(['./components/pages/userConfiguration/notifications/UserNotifications.vue'], () => {
        resolve(require('./components/pages/userConfiguration/notifications/UserNotifications.vue'), 
        'user')
    })
}

export const routes = [
    {
        path: '/catstats',
        components: {
            default: Catstats,
            'side-menu': SideMenu
        },
        children: [
            {
                path: '',
                redirect:  {name: 'CatstatsOverview'}
            },
            {
                path: 'overview',
                name: 'CatstatsOverview',
                component: CatstatsOverview
            }, 
            {
                path: 'tags',
                name: 'Tags',
                component: CatstatsTags
            }, 
            {
                path: 'cases',
                name: 'Cases',
                component: CatstatsCases
            } 
        ]
    },
    {
        path: '/configuration',
        components: {
            default: Configuration,
            'side-menu': SideMenu
        },
        children: [
            {
                path: '',
                redirect:  {name: 'AccessConfiguration'}
            },
            {
                path: 'access',
                name: 'AccessConfiguration',
                component: AccessConfiguration
            },
            {
                path: 'executiveFeeds',
                name: 'ExecFeedsConfiguration',
                component: ExecFeedsConfiguraion
            },
            {
                path: 'unitsTimezone',
                name: 'UnitsAndTimezoneConfiguration',
                component: UnitsAndTimezoneConfiguration
            },
            {
                path: 'workers',
                name: 'WorkersConfiguration',
                component: WorkersConfiguration
            },
            {
                path: 'workgroups',
                name: 'WorkgroupsConfiguration',
                component: WorkgroupsConfiguration
            },
            {
                path: 'notifications',
                name: 'NotificationsConfigurations',
                component: NotificationsConfiguraion
            },
            {
                path: 'customMetrics',
                name: 'CustomMetricsConfiguration',
                component: CustomMetricsConfiguration
            }
        ]
    },
    {
        path: '/userConfiguration',
        components: {
            default: UserConfiguration,
            'side-menu': SideMenu
        },
    },
    {
        path: '/forbidden',
        components: {
            default: Forbidden,
        }
    },
    {
        path: '/serviceDesk',
        components: {
            default: ServiceDesk,
            'side-menu': SideMenu
        },
        children: [
            {
                path: '',
                redirect:  {name: 'Historical Coverage'}
            },
            {
                path: 'historicalCoverage',
                name: 'Historical Coverage',
                component: CapacityMetricsHistoricalCoverage,
            },
            {
                path: 'weeklyPatterns',
                name: 'Weekly Patterns',
                component: CapacityMetricsWeeklyPatterns,
            },
            {
                path: 'dailyDrilldown',
                name: 'Daily Drilldown',
                component: CapacityMetricsDailyDrilldown,
            },
            {
                path: 'liveStats',
                name: 'Live Stats',
                component: LiveStats
            },
            {
                path: 'forecast',
                name: 'Forecast',
                component: Forecast
            },
            {
                path: 'scheduler',
                name: 'Scheduler',
                component: Scheduler
            },
        ]
    },
    {
        path: '/wam',
        components: {
            default: Wam,
            'side-menu': SideMenu
        },
        children: [
            {
                path: '',
                redirect:  {name: 'WamOverview'}
            },
            {
                path: 'overview',
                name: 'WamOverview',
                component: WamOverview
            },
        ]
    },
    {
        path: '/accountSummary',
        components: {
            default: AccountSummary,
            'side-menu': SideMenu
        },
        children: [
            {
                path: '',
                redirect:  {name: 'AccountSummaryOverview'}
            },
            {
                path: 'overview',
                name: 'AccountSummaryOverview',
                component: AccountSummaryOverview
            }

        ]
    },

    {
        path: '/login',
        name: 'Login Page',
        components: {
            default: Login
        },
        meta: {
            doesNotRequireLogin: true,
        },
    },

    //Logout

    {
        path: '/pageNotFound',
        components: {
            default: PageNotFound,
        }
    },
    {
        path: '*',
        redirect: '/pageNotFound'
    },
    {
        path: '/processMining',
        components: {
            default: ProcessMining,
            'side-menu': SideMenu
        },
        children: [
            {
                path: '',
                redirect:  {name: 'ProcessMiningOverview'}
            },
            {
                path: 'overview',
                name: 'ProcessMiningOverview',
                component: ProcessMiningOverview
            },
            {
                path: 'processMap',
                name: 'ProcessMiningProcessMap',
                component: ProcessMiningProcessMap
            },
        ]
    },
    {
        path: '/',
        name: 'Home',
        components: {
            default: NavigationPage,
        }
    }
];
