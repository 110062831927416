<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container black-text">

                <div class="modal-body-and-foot-container">
                    <div class="modal-header">
                        <h2><strong>{{ modalLabel }}</strong></h2>
                        <img
                            src="@Images/icons/icon_delete.svg"
                            @click="closeModal"
                            class="delete-icon"
                            alt="Delete Icon"
                        >
                    </div>
                    <div class="modal-body">
                        <div class="base-config-section">
                            <div class="base-info">
                                <span class="base-info-item">Name</span>
                                <div v-if="duplicateName" class="c-error error-message-tooltip">{{ duplicateNameMessage }}</div>
                                <div v-if="functionalAreaName.trim() == ''" class="c-error error-message-tooltip">{{ emptyNameMessage }}</div>
                                <input 
                                    type="text" 
                                    v-model="functionalAreaName"
                                    v-on:input="nameValidation" 
                                    :class='`c-input--text base-info-item ${duplicateName ? "alert" : "" }`'
                                    style="width:20rem"
                                    :disabled="textDisabled"
                                />
                            </div>
                        </div>
                        <div class="list-container">
                            <EntityList 
                                :columnNames="entityListColumnConfigs.columnNames"
                                :cells="entityListColumnConfigs.cells"
                                :sortableCells="entityListColumnConfigs.sortableCells"
                                :cellTypes="entityListColumnConfigs.cellTypes"
                                :columnWidths="entityListColumnConfigs.columnWidths" 
                                :cellWidths="entityListColumnConfigs.cellWidths" 
                                :centerAlignedColumns="entityListColumnConfigs.centerAlignedColumns"
                                :centerAlignedCells="entityListColumnConfigs.centerAlignedCells"
                                :entityData="includedWorkGroups"
                                :entityType="entityType"
                                listType="include"
                                class="margin-right"
                                :listTypeInfo="entityListTypeInfo"
                                @emitAction="handleIncludeOrExcludeEmit"
                                :moveAllButtons="isExcludedMoveAll"
                                :inputDisabled="textDisabled"
                            />
                            <EntityList 
                                :columnNames="entityListColumnConfigs.columnNames"
                                :cells="entityListColumnConfigs.cells"
                                :sortableCells="entityListColumnConfigs.sortableCells"
                                :cellTypes="entityListColumnConfigs.cellTypes"
                                :columnWidths="entityListColumnConfigs.columnWidths" 
                                :cellWidths="entityListColumnConfigs.cellWidths" 
                                :centerAlignedColumns="entityListColumnConfigs.centerAlignedColumns"
                                :centerAlignedCells="entityListColumnConfigs.centerAlignedCells"
                                :entityData="excludedWorkGroups"
                                :entityType="entityType"
                                listType="exclude"
                                :listTypeInfo="entityListTypeInfo"
                                @emitAction="handleIncludeOrExcludeEmit"
                                :moveAllButtons="isIncludedMoveAll"
                                :inputDisabled="textDisabled"
                            />
                        </div>
                    </div>
                    <div class="modal-footer" >
                        <div v-if="displayStatus==='submitButton'" class="submit-button-container">
                            <button class="c-btn c-btn--outline footer-btn" @click="clearForm">CANCEL</button>
                            <button  class="c-btn footer-btn" @click.once="saveFunctionalArea()" :disabled="disableSave">
                                SAVE
                            </button>
                        </div>
                        <div v-else-if="displayStatus==='loading'" class="loader-tiny"></div>
                        <div v-else-if="displayStatus==='success'" class="flex-row">
                            <img
                                src="@Images/icons/icon_checkmark.svg"
                                class="ticket-icon"
                                alt="Ticket icon"
                            > 
                            <div>
                                <span class="success-failure-label">
                                Functional Area Saved!
                                </span>
                            </div>
                        </div>                        
                        <div v-if="displayStatus==='failure'" class="flex-row">
                            <img
                                src="@Images/icons/icon_delete.svg"
                                class="ticket-icon"
                                alt="Ticket icon"
                            > 
                            <div @click="reEnableForm">
                                <span class="c-error success-failure-label c-clickable">
                                    {{ failureText }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import EntityList from '@Common/EntityList.vue'
import { axiosRequests } from '@/main'
import RouteQueryParamMixin from '@Mixins/RouteQueryParamMixin.vue'
import _ from 'lodash';

export default {
    name: "CreateFunctionalAreaModal",
    components: { EntityList },
    mixins: [RouteQueryParamMixin],
    props:{
        existingFunctionalAreas: { type: Array, required: false, default: () => [] },
        accountId: { type: [String, Number], required: true },
        accountConfiguration: { type: Object, required: true },
        workGroupsProp: { type: Array } // Array of wkgrp objects structured as {"key":"Name of wkgrp","value":1234,"selected":false}
    },
    mounted(){
        this.setUpInitialValues(this.workGroupIds)
    },
    data(){ 
        return {
            modalLabel: "Save Functional Area",
            duplicateName: false,
            duplicateNameMessage: "Write a Functional Area name that does not match an existing one",
            functionalAreaName: "",
            emptyNameMessage : "Please enter a Functional Area Name",
            displayStatus: "submitButton",
            textDisabled: false,
            failureText: "Failed to Save Functional Area",
            excludedWorkGroups: [],
            includedWorkGroups: [],
            inScope: this.inScope || true,
            entityType: "workgroup",
            entityListTypeInfo: {
                include: {
                    displayName: "Include", // display name on buttons
                    infoText: "When selecting this functional area, these workgroups will be selected.",
                    title: "Include"
                },
                exclude: {
                    displayName: "Exclude",
                    infoText: "When selecting this functional area, these workgroups will be de-selected.",
                    title: "Exclude"
                }
            },
            entityListColumnConfigs: {
                columnNames: ["Workgroup", "Action"],
                cells: ["name", "action"],
                sortableCells: ["name"],
                cellTypes: ["String"],
                columnWidths: [54, 44],
                cellWidths: [66, 34],
                centerAlignedCells: [ "action"],
                centerAlignedColumns: ["Action"]
            },
            isLoading: false,
            savedFunctionalArea: null // savedFunctionalArea object -- if it exists then the modal should display the current workgroups selected per this object
        }
    },
    computed: {
        getAccountId() {
            return parseInt(this.accountId)
        },
        /**
         * expected workGroupIds value is a string of ids separated by commas (e.g. "123,124,135")
         */
        workGroupIds() { 
            let workGroupIds = this.getRouteQueryParam('workgroupID')
            if(workGroupIds && workGroupIds.length > 0) {
                return workGroupIds
            }
            return null
        },
        queryParams() {
            let returnValue = {
                    accountId: this.accountId,
                    name: this.functionalAreaName,
                    includedWorkGroupIds: this.includedWorkGroups.map(wkgrp => wkgrp.value) // List<Long>
                }

            return returnValue
        },
        disableSave() {
            return this.duplicateName || this.functionalAreaName.trim() == '' || this.queryParams.includedWorkGroupIds.length == 0
        },
        isExcludedMoveAll() {
            return [
                {label: "All Excluded", event: () => {this.handleIncludeOrExcludeEmit('include', 'exclude', null)}}
            ]
        },
        isIncludedMoveAll() {
            return [
                {label: "All Included", event: () => {this.handleIncludeOrExcludeEmit('exclude', 'include', null)}}
            ]
        }
    },

    methods: {
        closeModal() {
           if (this.displayStatus != "loading") {
            this.$emit("closeModal", this.savedFunctionalArea)
           }
        },
        /**
         * Method for initializing form values if workGroups have been selected prior to opening the CreateFunctionalAreaModal.
         * If workGroups have not been selected then default will have all workGroups in the excluded list.
         * @param {Array} workGroupIds list of selected workgroup ids to be set as the includedWorkGroupIds for the new functionalArea
         */
        setUpInitialValues(workGroupIds) {
            if (workGroupIds && workGroupIds.length > 0) {
                let wkGrpIdsAsNum = workGroupIds.split(",").map(Number)
                this.includedWorkGroups = this.transformWkGrps(this.workGroupsProp.filter( (wkgrp) => wkGrpIdsAsNum.includes(wkgrp.value)))
                this.excludedWorkGroups = this.transformWkGrps(this.workGroupsProp.filter( (wkgrp) => !wkGrpIdsAsNum.includes(wkgrp.value)))
            } else {
                this.includedWorkGroups = []
                this.excludedWorkGroups = this.transformWkGrps(this.workGroupsProp)
            }
        },
        /**
         * Method to create a deep copy/clone of the original workGroups list and transform each 
         * workGroup object of {"key":"Name of wkgrp","value":1234,"selected":false}
         * into {"key":"Name of wkgrp", "name": "Name of wkgrp","value":1234,"selected":false} 
         * since the EntityList component expects a "name" field
         * @param {Array} workGroups list of workGroups from the workGroupsProp prop
         */
        transformWkGrps(workGroups) {
            // deep copy to avoid mutating original workGroups prop object
            let wkGrpCopy = [..._.cloneDeep(workGroups)]
            return wkGrpCopy.map((wkgrp) => ({...wkgrp, name: wkgrp.key}))
        },
        nameValidation(event){
            let newName = event.target.value.trim().toLowerCase()
            let newNameFoundInExistingFunctionalAreas = this.existingFunctionalAreas && this.existingFunctionalAreas.map(it => it.name.toLowerCase()).includes(newName)

            if (newNameFoundInExistingFunctionalAreas) {
                this.$set(this.$data, "duplicateName", true) 
            }
            else {
                this.$set(this.$data, "duplicateName", false) 
            }
        },

        /**
         * Method for saving a new functional area
         */
        saveFunctionalArea(){
            this.displayStatus = "loading"
            this.textDisabled = true
            let requestBody = this.queryParams
            let postUrl = "functionalArea"
            axiosRequests
                .post(postUrl, requestBody)
                .then(({ data }) => {
                    this.displayStatus = "success"
                        this.savedFunctionalArea = data
                })
                .catch(error => {
                    this.displayStatus = "failure"
                    console.log(error)
                    console.log(error.response.data.message)
                })
        },
        clearForm(){
            this.$emit("clearModal")
        },
        reEnableForm(){
            this.displayStatus = "submitButton"
            this.textDisabled = false
        },
        handleIncludeOrExcludeEmit(oldType, newType, workGroup) {
            this.updateData(oldType, newType, workGroup)
            
        },
        updateData(oldType, newType, workGroup){
            if(!this.isDirty){
                this.isDirty = true
            }
            let switchFromIncludeToExclude = oldType == "include" && newType == "exclude"
            let switchFromExcludeToInclude = oldType == "exclude" && newType == "include"


            // if no workGroup is specified, move _everything_ from oldType to newType
            if (workGroup != null) {
                if (switchFromIncludeToExclude) {
                    // remove workGroup from includedWorkGroups array by index & push it to the end of excludedWorkGroups array
                    let idx = this.includedWorkGroups.findIndex(wkGrp => wkGrp.value == workGroup.value)
                    this.$delete(this.includedWorkGroups, idx)
                    this.excludedWorkGroups.push(workGroup)
                }
                if (switchFromExcludeToInclude) {
                    // remove workGroup from excludedWorkGroups array by index & push it to the end of includedWorkGroups array
                    let idx = this.excludedWorkGroups.findIndex(wkGrp => wkGrp.value == workGroup.value)
                    this.$delete(this.excludedWorkGroups, idx)
                    this.includedWorkGroups.push(workGroup)
                }
            } else {
                if (switchFromIncludeToExclude) {
                    this.excludedWorkGroups = [...this.excludedWorkGroups, ...this.includedWorkGroups]
                    this.includedWorkGroups = []
                }
                if (switchFromExcludeToInclude) {
                    this.includedWorkGroups = [...this.includedWorkGroups, ...this.excludedWorkGroups]
                    this.excludedWorkGroups = []
                }
            }
        },
        handleCategoryLoadingEmit(boolValue) {
            this.catsLoading = boolValue
        }
    },
    watch: {
        workGroupIds: {
            deep: true,
            handler() {
                this.setUpInitialValues(this.workGroupIds)
            }
        }
    } 
}


</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 17rem;
    width: 86vw;
    height: 100%;
    background-color: rgba(176, 190, 197, 0.5);
}

.modal-container {
    width: 58vw;
    max-height: 95vh;
    margin: 1.75rem 8rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    box-shadow: -4px 4px 4px rgba(49, 48, 48, 0.1);
    border-radius: 0.6rem;
    display:flex;
    flex-direction: column;
    font-size:16px;
}

.modal-header {
  margin-top: 0;
  padding-top: 0.5rem ;
  border-bottom: 1px solid #444;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.modal-body {
    padding:  3rem;
    display: block;
    align-items: right
}
.modal-footer{
    width: 100%;
    margin-bottom: 25px;
}

.delete-icon {
    width: 2rem;
    cursor: pointer;
}

.base-info{
    display: table;
    position: relative;
    width: 100%;
    align-items: center;
    padding-bottom: 5px;
}

.base-info-item{
    display: table-cell;
}

.config-section{
    margin-top: 2rem;
}

.section-header {
    margin-bottom: 1rem;
    text-decoration: underline;
}

thead, tbody{
 border-bottom: 1px solid black;
}

td{
    padding:0.25rem 0 0.25rem 0;
}

tr{
    padding-bottom: 0.25rem;
}

.c-input--text, .c-input--select{
    width: 5.5rem;
    height: 2rem;
    font-size: 16px;
}

.target-input{
    display: inline-flex;
    position: relative;
}

.target-unit, .target-unit-static {
    margin: 0 0.5rem;
}

.target-unit-static{
    padding-top:0.5rem;
}

.share{
    padding-top:0.5rem;
    width: 30%;

}

.column-weight{
    width: 20%;
}
.column-status{
    width: 20%;
}

.column-target{
    width: 25%;
}

.disabled{
    color: gray;
}

.error-message-tooltip{
    position:absolute;
    bottom:100%;
    white-space: nowrap;
    font-size: 12px;
    content: '';
    background-color: #FED0D0;
}

.error-message-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #FED0D0 transparent transparent transparent;
}

.submit-button-container{
    display: flex;
    justify-content: center;
}
.footer-btn{
    width: 40%;
    margin: 1rem;
}

.c-btn:disabled{
    background-color: gray;
    opacity: 40%;
    cursor: default;
}

.flex-column{
    display: flex;
    flex-direction: column;
}
.flex-row{
    display: flex;
    justify-content: center;
}
.success-failure-label{
    font-size: 20px;
}
.ticket-icon{        
    height: 35px;
    width: 35px;
    margin-right: 7px;
}
.c-clickable{
    cursor: pointer;
}
.active-switch{
    vertical-align: middle;
    margin-right: 10px;
} 

/* style for the exclusion lists */
.list-container{
    display:flex;
    justify-content: space-evenly;
}
.margin-right{
    margin-right:10px;
}

/* style to simulate disabling dropdown & exclusion lists after saving functionalArea */
.div-disabled{
    background-color: gray;
    opacity: 40%;
    cursor: default;
}

/* style to override the sideNav's light colored text */
.black-text{
    color: #444;
}

/* style to override specific html sections defined in the EntityList component */
div >>> .list-count{
    margin-top: 10px !important
}

</style>

