<template>
    <div>
        <div class="title-info-container">
            <div class="list-title">
                {{ listTitle }}
            </div>
            <select v-if="selectTypes" class="c-input--select group-by-filter-select" v-model="selectType" :disabled="inputDisabled" @change="handleSelectType">
                <option v-for="(selectType, selectTypeKey) in selectTypes" :key="selectTypeKey" :value="selectTypeKey">{{ selectType.label }}</option>
            </select>
            <InfoTooltip
                :infoText="currentInfoText"
            />
        </div>
        <div class="flex-row" style="justify-content:space-between">
            <div class="list-count">
                {{ `${numEntity} ${this.entityTypeFormatted}s` }}
            </div>
            <div class="flex-row list-count">
                <div 
                    v-for="button in moveAllButtons" 
                    :key="button.label" 
                    :class="[inputDisabled ? 'disabled-action-button' : 'action-button primary-color-button primary-border-active-button', 'base-action-button']"
                    @click="buttonClickEvent(inputDisabled, button.event)"
                >
                    {{ button.label }}
                </div>
            </div>
        </div>
        <div class="entity-list primary-thin-border">
            <div :class="[inputDisabled ? 'disabled-header-row' : 'header-row', 'base-header-row', 'flex-row']">
                <div
                    v-for="(column, index) in columnNames"
                    :key="column"
                    :class="['base-cell', index == 0 ? 'first-column-padding-left' : '', centerAlignedColumns.includes(column) ? 'centered-cell' : '']"
                    :style="`width:${columnWidths[index]}%`"
                >
                    <span class="pad-right-title">{{columnNames[index]}}</span>
                    <SortDirectionTernary
                        v-if="sortableCells.includes(cells[index])"
                        :deliveredState="sortState[cells[index]]"
                        @sortAsc="sortData(cells[index]+',asc', cellTypes[index])"
                        @sortDesc="sortData(cells[index]+',desc', cellTypes[index])"
                    />
                </div>
            </div>
            <div :class="[inputDisabled ? 'disabled-list-body' : 'list-body', 'base-list-body']">
                <div
                    class="list-row flex-row"
                    v-for="(entity, index) in entityDataComputed"
                    :key="index"
                >
                    <div
                        :class="[ cell == 'action' ? 'action-cell action-cell-body' :'entity-cell entity-cell-body', centerAlignedCells.includes(cell) ? 'centered-cell' : '']"
                        v-for="(cell, cellIndex) in cells"
                        :key="cell+cellIndex"
                        :style="`width:${cellWidths[cellIndex]}%`"
                    >
                        <template v-if="cell != 'action'">
                            <template v-if="abbreviatedCells.includes(cell)">
                                <div class="abbreviated-cell" v-tooltip.bottom-start="entity[cell]">{{ entity[cell] }}</div>
                            </template>
                            <template v-else >{{ entity[cell] }}</template>
                        </template>
                        <template v-if="cell == 'action'">
                            <div
                                v-if="proposed"
                                :class="[inputDisabled ? 'disabled-action-button' : 'action-button primary-color-button primary-border-active-button', 'base-action-button']"
                                @click="emitAction('remove', entity)"
                            >
                                Remove
                            </div>
                            <template v-else>
                                <div
                                    v-for="(listTypeObj, keyName, index) in actionOptions" :key="keyName+index"
                                    :class="[inputDisabled ? 'disabled-action-button' : 'action-button primary-color-button primary-border-active-button', 'base-action-button']"
                                    @click="emitAction(keyName, entity)"
                                >
                                    {{listTypeObj.displayName}}
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import InfoTooltip from '@Common/InfoTooltip.vue'
import SortDirectionTernary from "@Common/SortDirectionTernary.vue"
import _ from 'lodash'
import { titleCase } from  '@/utils.js'

/**
 * A generalized table component utilized for the Custom Metric configuration, Functional Area and replaces the worker specific/customized WorkerList component 
 * previously utilized in the WorkerScope & WorkerInclusionModal components for the Worker configuration page.
 * The container or parent component for this is expected to contain handler methods for the expected behavior of
 * moving the entity objects between multiple variations of this table. 
 *  (e.g. the CreateOrEditMetricModal's @emitAction="handleIncludeOrExcludeEmit" or the WorkerScope/WorkerInclusionModal @emitAction="scopeEmit")
 */
export default {
    name: "EntityList",
    props: {
        columnNames:{type: Array}, // list of column names to be displayed
        cells:{type: Array}, // list of corresponding fields from data object
        sortableCells:{type: Array}, // list of fields that are expected to be sortable
        cellTypes:{type: Array}, // list of field types - each element to match with coresponding index of the cells array
        columnWidths:{type: Array}, // width percentage for each column specified
        cellWidths:{type: Array}, // width percentage for each cell specified
        centerAlignedCells:{ // list of cells that should be center aligned when displayed
            type: Array,
            required: false,
            default: () => []
        },
        centerAlignedColumns:{ // list of columns that should be center aligned when displayed
            type: Array,
            required: false,
            default: () => []
        },
        abbreviatedCells: {
            type: Array,
            required: false,
            default: () => []
        },
        entityData: {type: Array}, // list of data objects (e.g. worker, stage, functionalArea)
        entityType: {type: String}, // defines what the entity object is (e.g. worker, stage, work_group)
        listType: {type: String}, // e.g. for workers (outOfScope, inScope, robotic), for stages/workgroups/functionalArea (include, exclude)
        listTypeInfo: {type: Object}, // object that contains sub-objects e.g. {listType: {displayName: "name on moveAll button", infoText: "string", title: "string"}, ...}
        inputDisabled: {type: Boolean, default: false},
        proposed: {type: Boolean, default: false}, // utilized to hide the typeNamed buttons and replace with Remove button -- currently only used in the WorkerInclusionModal component
        moveAllButtons: {type: Array, default: () => []},
        selectTypes: {type: Object},
        selectTypeBy: {type: String}
    },
    components: { InfoTooltip, SortDirectionTernary },
    data(){
        return {
            sortState: {},
            defaultSort: 'name,asc',
            selectType: this.selectTypeBy
        }
    },
    methods: {
        emitAction(newType, entityObj){
            if(!this.inputDisabled){
                this.$emit('emitAction', this.listType, newType, entityObj)
            }
        },
        buttonClickEvent(inputDisabled, event) {
            if(!this.inputDisabled){
                event()
            }
        },
        sortData(sort=this.defaultSort, type="Number") {
            if (sort != undefined) {
                const [attr, dir] = sort.split(',')
                const sign = dir == 'asc' ? 1 : -1
                // sorting for strings requires a slightly different compare function
                if (type == "String") {
                    this.entityData.sort((a, b) => a[attr].localeCompare(b[attr]) * sign )
                } else {
                    this.entityData.sort((a, b) => (a[attr] - b[attr]) * sign)
                }
                this.sortState = {[attr]: sign}
            }
        },
        handleSelectType(){
            this.$emit('typeChange', this.selectType)
        }
    },
    computed: {
        listTitle(){
            return this.listTypeInfo[this.listType].title
        },
        currentInfoText(){
            return this.listTypeInfo[this.listType].infoText
        },
        entityTypeFormatted() {
            return titleCase(this.entityType.replace('_', ' '))
        },
        entityDataComputed(){      
            // this retains the data order
            if(!_.isEmpty(this.sortState)) {
                let sortCellName = Object.keys(this.sortState)[0] // sortState only has 1 attribute
                let sortCellIdx = this.cells.indexOf(sortCellName)
                let state = (this.sortState[sortCellName] > 0) ? "asc":"desc"
                this.sortData(`${sortCellName},${state}`, this.cellTypes[sortCellIdx])
            } else if(this.defaultSort){
                let sortCellName = this.defaultSort.split(',')[0]
                let sortCellIdx = this.cells.indexOf(sortCellName)
                this.sortData(this.defaultSort, this.cellTypes[sortCellIdx])
            }
            return this.entityData
        },
        numEntity(){
            return this.entityDataComputed.length
        },
        actionOptions() {
            // filter available action options -- only show options that are not the specified listType
            let filteredKeys = Object.keys(this.listTypeInfo).filter( keyName => keyName != this.listType)
            return filteredKeys.reduce((obj, key) => ({ ...obj, [key]: this.listTypeInfo[key] }), {})
        },
        getSelectTypeBy(){
            return this.selectTypeBy
        }
    },
    watch: {
        getSelectTypeBy: {
            deep: true,
            handler() {
                this.selectType = this.selectTypeBy
            }
        }
    }
}
</script>

<style scoped>
.base-header-row{
    height: 35px;
    font-weight: bold;
    font-size: 14px;
}
.header-row{
    background: rgba(39, 149, 251, 0.15);
    
}
.disabled-header-row{
    background: rgba(33, 33, 33, 0.3);
}
.list-row{
    height: 30px;
    padding: 10px 10px;
    border-bottom: 1px solid rgba(39, 149, 251, 0.25);
}
.flex-row{
    display: flex;
}
.base-action-button{
    box-sizing: border-box;
    border-radius: 3px;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 5px;
    height: 20px;
    text-align: center;
    margin: 0 1px;
}
.action-button:hover{
    cursor: pointer;
}
.disabled-action-button{
    background: rgba(33, 33, 33, 0.3);
    color: #212121;
}
.entity-list{
    width: 250px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 3px;
    -webkit-column-gap:0;
    column-gap:0;
}
.base-list-body{
    height: 195px;
    overflow-y: scroll;
    font-weight: normal;
    font-size: 12px;
}
.list-body{
    background: white;
}
.disabled-list-body{
    background: rgb(200, 200, 200);
}
.action-cell-body{
    display: flex;
    align-items: center;
    justify-content: center;
}
.action-cell{
    width:83px;
    justify-content: center;
}
.entity-cell{
    width: 83px;
}
.entity-cell-body{
    display: flex;
    align-items: center;
    font-size: 10px;
}
.base-cell{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.title-info-container{
    display:flex;
}
.list-title{
    font-size: 16px;
    margin-right: 5px;
}
.list-count{
    margin-top: 20px;
    font-size: 12px;
    margin-bottom: 5px;
    height: 20px;
}
.pad-right-title{
    padding-right: 4px;
}
.centered-cell{
    justify-content: center;
}
.first-column-padding-left{
    padding-left: 10px;
}
.abbreviated-cell{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.group-by-filter-select{
    width:140px;
    height:25px;
    font-size: 12px;
    padding-left:11px;
    margin-right:10px;
    padding-top:0px;
    padding-bottom:0px;
}
</style>
