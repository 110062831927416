import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import _ from 'lodash'
import moment from 'moment'
import { router } from "@/router"
import { axiosRequests } from '@/main'
import * as msal from '@azure/msal-browser'
import { consoleLog } from "@/utils.js"

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
    key: 'vuex', 
    storage: window.localStorage
  })


function parseUserFromToken(token) {
    let payload = token.split(".")[1]
    let parsedPayload = JSON.parse(atob(payload))
    let roles = []
    let privileges = []
    let dashboardAccess = zipAccountsDashboards(parsedPayload.accounts, parsedPayload.dashboards)
    let adminAccounts = parsedPayload.adminAccounts
    
    parsedPayload.authorities.forEach(auth =>{
        if(auth.includes("ROLE_")){
            roles.push(auth)
        }else{
            privileges.push(auth)
        }
    })
    let user = {
        username: parsedPayload.sub,
        roles,
        privileges,
        dashboards: dashboardAccess,
        adminAccounts: adminAccounts,
        userId: parsedPayload.userId
    }
    return user
}

// helper function to zip account dashboard permissions into object
function zipAccountsDashboards(accounts, dashboards) {
    let results = {}
    for (var i = 0; i < accounts.length; i++) {
      if (!results[accounts[i]]) {
        results[accounts[i]] = []
      }
      results[accounts[i]].push(dashboards[i])
    }
    return results
}
function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export const store = new Vuex.Store({
    state: {
        user: null,
        errorMessage: null,
        systemMessage: "",
        hasExistingData: {
            'incidents': false,
            'alerts': false
        },
        minDate: null,
        maxDate: null,
        loading: {
            existingData: true,
            dataRange: true,
            accountConfiguration: true,
            login: false
        },
        accountWkgrpConfigs: {
            account: {
                id: null, 
                name: null
            },
            timeZone: null,
            fteHours: null,
            fteBreaks: null,
            processMapType: null
        },
        dataRangeDisabled: null,
        loginLock: false,
        useAAD: false,
        aadClientId: null,
        aadTenantId: null,
        msalConfig: null,
        timeoutID: null,
        maxIdleMinutes: 59,
        popupWindow: null,
        filterParams: null,
        dsDownloadEnabled: false
    },
    getters: {
        // only used for the beforeEach router guard navigation for configuration page
        isAdmin (state) {
            try {
                return state.user.roles.includes("ROLE_ADMIN") || state.user.adminAccounts.includes(parseInt(state.selectedAccountId))
            } catch (err) {
                return false
            }
        },
        isLoggedIn (state) {
            return !!state.user
        },

    },
    mutations: {
        setExistingData (state, payload) {
            state.hasExistingData = payload.hasExistingData
        },
        setUser (state, payload) {
            state.user = payload.user
        },
        setSystemMessage (state, payload) {
            state.systemMessage = payload.message
        },
        setDataRange (state, payload){
            state.minDate = moment(payload.minDate, 'YYYY-MM-DD').format("MM/DD/YYYY")
            state.maxDate = moment(payload.maxDate, 'YYYY-MM-DD').format("MM/DD/YYYY")
        },
        setAccountConfiguration (state, payload){
            if (payload.content[0]) {
                state.slaEnabled = payload.content[0].slaEnabled
                state.accountWkgrpConfigs.processMapType = (payload.content[0].processMapType != null) ? payload.content[0].processMapType : "work_group"
                state.accountWkgrpConfigs.account.id = payload.content[0].account.id
                state.accountWkgrpConfigs.account.name = payload.content[0].account.name
                state.accountWkgrpConfigs.timeZone = payload.content[0].timeZone
                state.accountWkgrpConfigs.fteHours = payload.content[0].fteHours || 40.0
                state.accountWkgrpConfigs.fteBreaks = payload.content[0].fteBreaks || 6.0
                state.dataRangeDisabled = payload.content[0].dataRangeDisabled
                state.accountWkgrpConfigs.dateRangeMonths = payload.content[0].dateRangeMonths
            } else {
                // default to slaEnabled when no account configuration exists
                state.slaEnabled = true
                state.dateRangeMonths = 3
            }
        },
        setTimeZone (state, payload) {
            state.accountWkgrpConfigs.timeZone = payload.timeZone
        },
        setLoading(state, payload) {
            // expect loading payload to be of form
            //{ key: 'existingDate', value: true}
            state.loading[payload.key] = payload.value
        },
        resetState (state) {
            state.user = null
            state.systemMessage = ""
            state.hasExistingData = {
                tickets: false,
                alerts: false
            }
            // default to slaEnabled when no account configuration exists
            state.slaEnabled = true
        },
      resetPage (state) {
        state.systemMessage = ""
        state.hasExistingData = {
            tickets: false,
            alerts: false
        }
        // default to slaEnabled when no account configuration exists
        state.slaEnabled = true
      },
      setErrorMessage(state , payload) {
        state.errorMessage = payload
      },
      setLoginLock(state, payload) {
          state.loginLock = payload
      },
      setAADVars(state, payload) {
        state.useAAD = payload.useAAD
        state.aadClientId = payload.aadClientId
        state.aadTenantId = payload.aadTenantId
      },
      setJWTExpiration(state, payload) {
          state.jwtExpiration = payload
      },
      setDsDownloadEnabled(state, payload) {
        state.dsDownloadEnabled = payload
      }
    },
    actions: {
        resetPage ({ commit }) {
          commit('resetPage')
        },
        async determineExistingData ({ commit }, payload) {
            commit('setLoading', {key: "existingData", value: true})
            let promises = payload.models.map(
                model => (model == 'incidentAlerts') 
                            ? axiosRequests.get(`incidents/exist?accountId=${payload.account.value}&incidentType=ALERT`)
                            : (model == 'interactionSegments') && payload.workgroups != null
                                ? axiosRequests.get(`interactionSegments/exist?accountId=${payload.account.value}&workGroupId=${payload.workgroups}`)
                                : axiosRequests.get(`${model}/exist?accountId=${payload.account.value}`)
            )
            
            // takes in a list of promises
            // executes all of them
            // returns their values in a list, with the same order as the promises
            await Promise.all(promises)
                .then(values => {
                    const hasExistingData = _.range(payload.models.length).reduce((existingData, index) => (
                        { ...existingData, [payload.models[index]]: values[index].data }
                    ), {})
                    commit('setExistingData', { hasExistingData })
                })
                .catch(e => console.log(e))
            commit('setLoading', {key: "existingData", value: false})
        },
        determineDataRange ({ commit }, payload) {
            axiosRequests
                .get("common/dataRange", {params: {accountId: payload.accountId}})
                .then(response => {
                        commit('setDataRange', response.data)
                        commit('setLoading', {key: "dataRange", value: false})
                })
                .catch(error => {
                    commit('setLoading', {key: "dataRange", value: false})
                    consoleLog("vuex.store@determineDataRange",error)
                })
        },
        setTimeZone ({ commit }, payload) {
            commit('setTimeZone', {timeZone: payload.timeZone})
        },
        getAccountConfiguration ({ commit }, payload) {
            commit('setLoading', {key: "accountConfiguration", value: true})
            axiosRequests
                .get("configuration/accounts", {params: {accountId: payload.accountId}})
                .then(response => {
                        commit('setAccountConfiguration', response.data)
                        commit('setLoading', {key: "accountConfiguration", value: false})
                })
                .catch(error => {
                    commit('setLoading', {key: "accountConfiguration", value: false})
                    consoleLog("vuex.store@getAccountConfiguration",error)
                })
        },
        logout ({ commit }) {
            axiosRequests.defaults.headers.authorization = null
            localStorage.removeItem('token')
            localStorage.removeItem('vuex')
            localStorage.removeItem('aad')
            commit('resetState')
        },
        processToken({ commit }, token)
        {
            const jwtExpiration = store.state.jwtExpiration - 1;
            // store the timeoutId to support multiple browser tabs
            if (jwtExpiration >= 1) {
                    consoleLog("vuex.store",`dispatched SilentRefreshToken: ${jwtExpiration}m`,'Debug')
                    store.state.timeoutId = setTimeout(_ => {
                        store.dispatch('silentRefreshToken')
                    }, jwtExpiration * 60 * 1000); // convert from minutes to milliseconds
            }
        },
        silentRefreshToken ({ commit }) {
            if(store.state.useAAD )
            {
               // refresh token
                /**
                * See here for more info on account retrieval:
                * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
                */
                const msalConfig = {
                    auth: {
                        clientId: store.state.aadClientId,
                        authority: `https://login.microsoftonline.com/${store.state.aadTenantId}/`,
                    },
                     cache: {
                        cacheLocation: "localStorage", // This configures where your cache will be stored
                        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
                    }
                };
                let msalInstance = new msal.PublicClientApplication(msalConfig)
                const accounts = msalInstance.getAllAccounts();
                const aad = JSON.parse(localStorage.getItem('aad'));
                const username = aad ? aad.username : null;

                if (!username || (accounts.length < 1)) {
                   store.dispatch('logout')
                   store.dispatch('setSystemMessage', { message: "Your session has timed out. Please log in again." })
                   router.push({path: '/login', query: {}}, () => {})
                }

               consoleLog("vuex.store@silentRefreshToken","about to acquireTokenSilent",'Debug');
               var loginRequest = { account : msalInstance.getAccountByUsername(username) };
               return msalInstance.acquireTokenSilent(loginRequest)
               .then(response => {
                        consoleLog("vuex.store@silentRefreshToken","Successfully refreshed msal token.",'Debug')
                        const username = response.account.username;
                        let payload = {accessToken: response.accessToken, idToken: response.idToken, username: response.account.username}
                        let aad = { 'username': payload.username, 'idToken': payload.idToken}
                        localStorage.setItem('aad',JSON.stringify(aad));
                        axios.post(`${process.env.BASE_URL}/login`, {data: payload})
                                    .then(resp => {
                                        consoleLog("vuex.store@silentRefreshToken","Successfully refreshed TK token.",'Debug')
                                        const token = resp.headers.authorization
                                        store.dispatch('processToken', { token })
                                        // We need to modify the API axios instance to send the new token with the requests
                                        axiosRequests.defaults.headers.authorization = token
                    })
                   .catch(error => {
                        consoleLog("vuex.store@silentRefreshToken","Silent refreshed msal token acquisition fails",'Debug');
                       if (error instanceof msal.InteractionRequiredAuthError) {
                             store.dispatch('logout')
                             store.dispatch('setSystemMessage', { message: "Your session has timed out. Please log in again." })
                             router.push({path: '/login', query: {}}, () => {})
                       } else {
                           console.warn(error);
                       }
               });
               })
            }
            else {
                store.dispatch('logout')
                store.dispatch('setSystemMessage', { message: "Your session has timed out. Please log in again." })
                router.push({path: '/login', query: {}}, () => {})
            }

        },
        loginFromToken ({ commit }, payload) {
            const user = parseUserFromToken(payload.token)
            axiosRequests.defaults.headers.authorization = payload.token
            commit('setUser', { user })
        },
        setSystemMessage ({ commit }, payload) {
            commit('setSystemMessage', payload)
        },
        triggerErrorMessage({ commit }, payload ) {
          commit('setErrorMessage', payload); // hold error message for 3 seconds
          setTimeout(() => commit('setErrorMessage', null), 3000)
        },
        loginFromForm ({ commit }, payload) {
            commit('setLoginLock', Date.now())
            //using axios.post directly since login route is different namespace from API
            axios.post(`${process.env.BASE_URL}/login`, {data: payload})
            .then(resp => {
                const token = resp.headers.authorization
                store.dispatch('processToken', { token })

                // We need to modify the API axios instance to send the new token with the requests
                const user = parseUserFromToken(token)
                axiosRequests.defaults.headers.authorization = token
                localStorage.setItem("token", token)
                commit('setUser', { user })
                if (payload.redirectUrl != null) {
                    router.push(payload.redirectUrl)
                } else {
                    router.push({name: "Home", path: "/"})
                }
                store.state.loading.login = false
                })
            .then(() => { commit('setLoginLock', false) })
            .catch(err => {
                console.log(err)
                commit('setLoginLock', false)
                if (err.response && err.response.status === 401) {
                    commit(
                        'setSystemMessage',
                        { message: "The username or password you've entered doesn't match any account." }
                    )
                } else {
                    commit(
                        'setSystemMessage',
                        { message: "Something went wrong, please try again in a few minutes" }
                    )
                }
            })
        },
        setAADVars ({ commit }, payload) {
            commit('setAADVars', payload)
        },
        setJWTExpiration({commit}, payload){
            commit('setJWTExpiration', payload)
        },
        setDsDownloadEnabled({commit}, payload){
            commit('setDsDownloadEnabled', payload)
        },
        setLoginLock({commit}, payload){
            commit('setLoginLock', payload)
        }
    },
    plugins: [vuexLocalStorage.plugin]
})
