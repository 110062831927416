<template>
    <div class="select-container">
        <div class="page-num-container">
            <div class="num-container">
                <div 
                    class="page-num"
                    @click="previousPages"
                >
                    &lt;&lt;
                </div>
            </div>

            <div
                v-for="page in pageNumbers"
                :key="page"
                class="num-container"
            >
                <div
                    :class="[
                        {'page-num': page - 1 != pageNumber},
                        {'selected-page': page - 1 == pageNumber}
                    ]"
                    @click="setPage(page)"
                >
                    {{ page }}
                </div>
            </div>

            <div class="num-container">
                <div 
                    class="page-num"
                    @click="nextPages"
                >
                    &gt;&gt;
                </div>
            </div>
        </div>
        <div class="showing-container">
            Showing {{ showingStart }} - {{ showingEnd }} of {{ totalElements }} records
        </div>
    </div>
</template>

<script>
export default {
    name: "PageNumberSelect",
    props:{
        totalPages: {
            type: Number
        },
        offset: {
            type: Number
        },
        numElements:{
            type: Number
        },
        pageNumber: {
            type: Number
        },
        totalElements: {
            type: Number
        }
    },
    data() {
        return {
            firstShowPage: 0
        }
    },
    computed:{
        showingStart(){
            let start = this.offset
            if (this.numElements > 0){ start +=1 }
            return start
        },
        showingEnd(){
            return this.offset + this.numElements
        },
        pageNumbers(){
            var i = 0
            let pages = []
            while(i < 5 & (i + this.firstShowPage + 1) <= this.totalPages){
                pages.push(this.firstShowPage + i + 1)
                i++
            }
            return pages
        }
    },
    methods:{
        nextPages(){
            if(this.firstShowPage + 6 >= this.totalPages){
                // The Math.max check is to handle the edge case where there are less than five pages of data
                this.firstShowPage = Math.max(0, this.totalPages - 5)
            }
            else{
                this.firstShowPage += 5
            }
        },
        previousPages(){
            if(this.firstShowPage - 5 < 0){
                this.firstShowPage = 0
            }
            else{
                this.firstShowPage -= 5
            }
        },
        setPage(page){
            this.$emit('emitFunc', page - 1)
        }
    },
    mounted(){
        // for most cases the first displayed page numbe should be the next smallest multiple of five to the selected page plus one
        if (this.pageNumber + 5 < this.totalPages) { 
            this.firstShowPage = Math.floor(this.pageNumber / 5) * 5  
        }
        // handle edge case where either their arent five pages of data or displaying five page select boxes would overrun the number of total pages
        else {
            this.firstShowPage = Math.max(0, this.totalPages - 5)
        }
        
    },
    watch:{
        totalPages:{
            deep: true,
            handler() {
                this.firstShowPage = 0
            }
        }
    }
}
</script>

<style scoped>
.select-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.page-num-container{
    display: flex;
    flex-direction: row;
}
.showing-container{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    font-size:12px;
}
.page-num{
    font-family: "Nunito Regular", Helvetica, sans-serif;
    font-size: 10px;
    color: rgba(33, 33, 33, 0.6);
    padding: 6px 12px;
    transition: background-color .3s;
    border: 1px solid #e2f1f8;
    border-radius: 4px;
}
.page-num:hover{
    background-color: rgba(226, 241, 248, 0.8);
    cursor: pointer;
}
.selected-page{
    background-color: #e2f1f8;
    color: #212121;
    padding: 6px 12px;
    border: 1px solid #b0bec5;
    font-size: 10px;
    transition: background-color .3s;
    border-radius: 4px;
}
.selected-page:hover{
    cursor: pointer;
}
.num-container{
    padding: 0px 2px;
}
</style>


