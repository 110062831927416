<template>
    <div class="cell-container">
            <a :href="link" target="_blank">
            <img src="@Images/icons/icon_external_link_green.svg" class="open-button" alt="open modal link">
            </a>
    </div>

</template>

<script>
export default {
    name: "LinkCell",
    props:{
        link: {
            type: String
        }
    }
}
</script>

<style scoped>
.cell-container{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.open-button{
    height: 1rem;
    width: 1rem;
    cursor: pointer;
}
img{
    max-width: unset;
}
</style>

