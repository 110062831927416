<template>
        <div class="info-icon">
				<div @mouseover="togglePlotInfo(true)" @mouseleave="togglePlotInfo(false)">
                	<img 
						v-if="showPlotInfo"
                	    src="@Images/icons/icon_info_green.svg"
                	    class="info-image"
                        alt="Info icon"
                	>
             		<img 
						v-else
                	    src="@Images/icons/icon_info.svg"
                	    class="info-image"
                        alt="Info icon"
                	>
				</div>
                <div v-if="showPlotInfo" class="info-text">
                    <div class="arrow-holder">
                        <div class="arrow-up"></div>
                    </div>
                    <div class="main-info-box">
                        <!-- info text slot -->
                        <span v-html="infoText"></span>
                    </div>
                </div>
        </div>
</template>

<script>
export default {
    name: 'InfoTooltip',
    props: {
        infoText: {
            type: String, default: "hello world"
        }
    },
    data () {
        return {
            showPlotInfo: false,
        }
    },
    methods: {
        togglePlotInfo(newValue){
            this.showPlotInfo = newValue
        }
    }
}
</script>

<style scoped>
.info-icon{
    margin-left: 2px;
    text-align: center;
}

.info-image{
    vertical-align: middle;
}

.info-icon:hover{
    cursor: pointer;
}

.info-text{
    position: absolute;
    z-index: 999999;
    margin-left: -6px;
    margin-top: -2px;
}

.main-info-box{
    background: #FFFFFF;
    border: 0.5px solid #B0BEC5;
    box-sizing: border-box;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
    padding:20px;
    font-size:12px;
    width: 20rem;
    line-height: 175%;
    text-align: left;;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 11px solid #B0BEC5;
}

.arrow-holder{
    padding-left:9px;
}
</style>
