import App from '@/App.vue'
import axios from 'axios'
import { store } from "@/store"
import { router } from "@/router"
import { ServerTable } from 'vue-tables-2'
import VuejsDialog from "vuejs-dialog"
import Vue from 'vue'
import vco from "v-click-outside";
import OutsideClick from '@Common/OutsideClick'
import VTooltip from 'v-tooltip'
import _ from 'lodash'

export const axiosRequests = axios.create({
    baseURL: process.env.BASE_URL + '/api/' + process.env.API_VERSION,
    headers: {
        authorization: localStorage.getItem("token")
    }
})

// filter function for truncating text
var filter = function(text, length, clamp){
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};


Vue.filter('truncate', filter);
Vue.use(VuejsDialog)
Vue.use(vco)
Vue.use(VTooltip)

Vue.use(ServerTable, [
    {
        texts: {
            filter: "Search:"
        },
        datepickerOptions: {
            showDropdowns: true
        }
    }
])

Vue.directive('outside-click', OutsideClick)
Vue.directive('tooltip', VTooltip.VTooltip)

VTooltip.options.defaultDelay = 500
VTooltip.options.defaultPlacement = 'auto'

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
