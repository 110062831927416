import { routes } from '@/routes'
import { store } from "@/store"
import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'
import { axiosRequests } from '@/main';

function verifyToken(token) {
    return axiosRequests.get("/", {
        headers: {
            authorization: token
        }
    })
}
Vue.use(VueRouter)

export const router = new VueRouter({
    routes: routes
})

/*
 * Before every route change, verify the token
 * If it's no longer valid, redirect to the login page, but store the desired URL as a query param
*/
export function loginRedirect(to, from, next) {
    // There's no need to redirect if the user is already at the login page
    if (to.path == "/login") {
        next()
        return
    }
    let token = localStorage.getItem('token')
    //Have to catch case when token is deleted/expired, but user tries to continue interaction with the app
    // if the token is empty, then the user has manually logged out, don't dispay error
    // if the token is simply invalid, inform the user that it's expired -> log in again
    verifyToken(token).catch(() => {
        if (token) {
            store.dispatch('logout')
            store.dispatch('setSystemMessage', { message: "Your session has timed out. Please log in again." })
        }
        router.push({path: '/login', query: {redirectUrl: to.fullPath}}, () => {}) // redirectUrl is for redirecting after login
    })
    next()
}
router.beforeEach((to, from, next) => loginRedirect(to, from, next))

/*
 * this middleware method is a workaround for the fact that vue router will redirect to index
 * when the app loads. It achieves this by determining if the app has been freshed/just opened up (by checking
 * the from and to arguments), then forcing a redirect to the page defined in the browser's url
 * The exception to this is when we legitimately refresh in the index page, which is handled by the `urlPath !== "/"` condition
*/
router.beforeEach((to,from,next) => {
    let redirectUri = window.location.hash.substr(1)
    let paramIndex = redirectUri.indexOf("?")
    let uriPath
    // if there are no query params, handle the case
    if (paramIndex !== -1) {
        uriPath = redirectUri.substring(0,redirectUri.indexOf("?"))
    } else {
        uriPath = redirectUri
    }
    //Fixes issue with accountId getting lost
    if(to.name==="Login Page" && store.getters.isLoggedIn){
        next({path: "/", name: "Home", replace:true })
    }
    else if(to.name === "Home" && from.name === null && uriPath !== "/" && uriPath!=redirectUri) {
        next(redirectUri)
    } 
    else {
        next()
    }
})

/*
 * Configuration pages are only accessible to admins and account_admins.
 * If a user attempts to navigate to a configuration page and they do not have at least one of these roles
 * they will be rerouted to the splash page.
 * If a user navigates to configuration page with query params set (e.g. category1/2/3, workgroup, agent, etc.) then those params will be saved in the store.state.filterParams field.
 * When the user navigates back out of the configuration pages to the other modules the route.query will be replaced with the filteredParams and the store.state.filterParams will be reset to null.
*/
router.beforeEach((to,from,next) => {
    let configPages = ["AccessConfiguration", "ExecDashboardConfiguration", "ExecFeedsConfiguration", "UnitsAndTimezoneConfiguration", "WorkersConfiguration", "WorkgroupsConfiguration", "CustomMetricsConfiguration"]
    /* 
     * A refresh on the configuration page causes the accountID in the router.currentRoute to disappear. 
     * This causes an infinite loop between this configuration beforeEach fn, the isAdmin fn which checks for the accountID, and the redirect beforeEach fn. 
     * To avoid this we utilize the store.state's selectedAccountId to keep track of the accountID for the isAdmin fn as this configuration beforeEach is the only fn that uses it.
    */
    if( router.currentRoute.query.accountID == null ) {
        store.state.selectedAccountId = to.query.accountID 
    } else {
        store.state.selectedAccountId = router.currentRoute.query.accountID
    }

    // from.name/to.name is null when page is refreshed -- include bool check to make sure it's not null
    let toConfigFromNavRoutes = configPages.includes(to.name) && Boolean(from.name) && !configPages.includes(from.name)
    let toNavRoutesFromConfig = configPages.includes(from.name) && Boolean(to.name) && !configPages.includes(to.name) && Boolean(store.state.filterParams)

    if( configPages.includes(to.name) && !store.getters.isAdmin ){
        next({path: "/", name: "Home", replace:true })
    } else if (toConfigFromNavRoutes) {
        // save queryParams from navRoutes to store state & continue to config pages
        store.state.filterParams = {...from.query}
        next()
    } else if (toNavRoutesFromConfig) {
        // update the queryParams with the stored filterParams when navigating back out from the config page
        let filterParams = store.state.filterParams
        // set store's filterParams back to null
        store.state.filterParams = null
        // based on the toNavRoutesFromConfig conditions filterParams shouldn't be null in this if block, but just in case default should be to.query
        next( { name: to.name, query: filterParams ? filterParams : to.query, replace: true } )
    }
    else {
        next()
    }
})


/*
 * If an account selection occurs when a worker is selected the worker query params should be removed from the `to` route. 
*/
router.beforeEach((to, from, next) => {
    let accountIdExistsAndChanging = (!!from.query.accountID && !!to.query.accountID) && (from.query.accountID !== to.query.accountID)
    let agentParamsIncludedInFrom = !!from.query.agentId && !!from.query.agentName
    let agentParamsInTo = !!to.query.agentId && !!to.query.agentName 
    // when there are agent params in the from.query & to.query when an account has changed remove them from the to.query
    if (accountIdExistsAndChanging && agentParamsIncludedInFrom && agentParamsInTo) {
        delete to.query.agentId
        delete to.query.agentName
        next( { name: to.name, query: to.query, replace: true } )
    } else {
        next()
    }
    

})

