<template>
    <div>
        <CreateFunctionalAreaModal 
            v-if="showCreateFunctionalAreaModal && showViewFunctionalAreaModal == false"
            :key="createFunctionalAreaModalComponentKey"
            :accountId="accountId"
            :existingFunctionalAreas="existingFunctionalAreas"
            :accountConfiguration="accountConfiguration"
            :workGroupsProp="workGroupsProp"
            @clearModal="createFunctionalAreaModalComponentKey+=1"
            @closeModal="closeCreateFAModal"
        />
        <ViewFunctionalAreaModal 
            v-if="showViewFunctionalAreaModal && showCreateFunctionalAreaModal == false"
            :key="viewFunctionalAreaModalComponentKey"
            :accountId="accountId"
            :existingFunctionalAreas="existingFunctionalAreas"
            :accountConfiguration="accountConfiguration"
            :workGroupsProp="workGroupsProp"
            @clearModal="viewFunctionalAreaModalComponentKey+=1"
            @closeModal="closeViewFAModal"
            @removeDeletedFA="updateExistingFAsWithDeletedFAs"
            @selectFA="selectFA"
        />
        <label class="middle-align">
            Functional Areas
            <div class="fa-icon" @mouseover="toggleViewFAInfo(true)" @mouseleave="toggleViewFAInfo(false)">
                <svg class="c-icon middle-align icon-pointer" aria-hidden="true" @click="openViewFunctionalAreaModal" >
                    <use href="../../assets/style/ckm-style/images/icons/icons.svg#list"></use>  
                </svg>
                <div v-if="showViewFAInfoTooltip" class="info-text">
                    <div class="arrow-holder">
                        <div class="arrow-up"></div>
                    </div>
                    <div class="main-info-box">
                        <!-- info text slot -->
                        <span class="black-text" v-html="viewFAInfoText"></span>
                    </div>
                </div>
            </div>
            <div class="fa-icon" @mouseover="toggleCreateFAInfo(true)" @mouseleave="toggleCreateFAInfo(false)">
                <svg class="c-icon middle-align icon-pointer" aria-hidden="true" @click="openCreateFunctionalAreaModal">
                    <use href="../../assets/style/ckm-style/images/icons/icons.svg#plusInCircle"></use> 
                </svg> 
                <div v-if="showCreateFAInfoTooltip" class="info-text">
                    <div class="arrow-holder">
                        <div class="arrow-up"></div>
                    </div>
                    <div class="main-info-box">
                        <!-- info text slot -->
                        <span class="black-text" v-html="createFAInfoText"></span>
                    </div>
                </div>
            </div>

        </label>
    </div>

</template>

<script>

import RouteQueryParamMixin from '@Mixins/RouteQueryParamMixin.vue'
import { axiosRequests } from '@/main'
import CreateFunctionalAreaModal from '@Common/functionalArea/CreateFunctionalAreaModal.vue'
import ViewFunctionalAreaModal from '@Common/functionalArea/ViewFunctionalAreaModal.vue'

export default {
    name: "FunctionalArea",
    components: { CreateFunctionalAreaModal, ViewFunctionalAreaModal },
    props: {
        accountId: { type: [String, Number], required: true },
        workGroupIds: {type: String, required: false},
        accountConfiguration: { type: Object, required: true },
        workGroupsProp: { type: Array }
    },
    mixins: [ RouteQueryParamMixin ],
    data(){
        return {
            createFunctionalAreaModalComponentKey: 0,
            viewFunctionalAreaModalComponentKey: 0,
            showCreateFunctionalAreaModal: false,
            showViewFunctionalAreaModal: false,
            existingFunctionalAreas: [],
            showViewFAInfoTooltip: false,
            showCreateFAInfoTooltip: false,
            viewFAInfoText: "View existing Functional Areas.",
            createFAInfoText: "Create a new Functional Area."
        }
    },
    mounted() {
        this.fetchFunctionalAreas()
    },
    methods: {
        fetchFunctionalAreas() {
            axiosRequests
                .get(`functionalArea?accountId=${this.accountId}`)
                .then(({ data }) => {
                    this.existingFunctionalAreas = data
                })
                .catch(e => console.log(e))

        },
        openViewFunctionalAreaModal() {
            if (this.showCreateFunctionalAreaModal == false) {
                this.showViewFunctionalAreaModal = true
            }
        },
        openCreateFunctionalAreaModal() {
            if (this.showViewFunctionalAreaModal == false) {
                this.showCreateFunctionalAreaModal = true
            }
        },
        /**
         * Method to handle closing the create or edit functionalArea modal
         * @param savedFunctionalArea nullable functionalArea object
         * Only invokes the updateExistingFAsWithNewFA when savedFunctionalArea is not null.
         * Sets showCreateFunctionalAreaModal to false and selectedMetric back to null
         */
        closeCreateFAModal(savedFunctionalArea) {
            this.showCreateFunctionalAreaModal = false
            if (Boolean(savedFunctionalArea)) {
                this.updateExistingFAsWithNewFA(savedFunctionalArea)
            }
        },
        /**
         * Method to handle closing the view functionalArea modal
         * @param selectedFunctionalArea functionalAreaTable object which contains data on the selectedFunctionalArea and the list of deletedFunctionalAreaIds
         * Sets showViewFunctionalAreaModal to false and selectedFunctionalArea back to null
         */
        closeViewFAModal() {
            this.showViewFunctionalAreaModal = false
            // selected functionalArea update of workGroups is handled by the selectFA callback method
        },
        /**
         * Method to update the data variable existingFunctionalAreas array to avoid slow reloading of functionalAreas page on modal close
         * @param savedFunctionalArea functionalArea object
         * Update the existingFunctionalAreas array with a new functionalArea 
         */
        updateExistingFAsWithNewFA(savedFunctionalArea) {
            // a new functionalArea was created -- add it to the existingFunctionalAreas array
            this.existingFunctionalAreas.push(savedFunctionalArea)
        },
        /**
         * Method to update the data variable existingFunctionalAreas array to avoid slow reloading of functionalAreas page on modal close
         * @param deletedFunctionalAreaId id of deleted functionalArea object
         * Update the existingFunctionalAreas array by removing the deleted functionalArea
         */
        updateExistingFAsWithDeletedFAs(deletedFunctionalAreaId) {
            this.existingFunctionalAreas = this.existingFunctionalAreas.filter((faObj)=> faObj.id != deletedFunctionalAreaId)
        },
        /**
         * Passes up the selected functionalArea's list of includedWorkGroupIds to the SideNavigation parent component
         * @param functionalAreaWorkGroupIds list of included workGroup ids for the selected functionalArea
         */
        selectFA(functionalAreaWorkGroupIds) {
            this.$emit("selectFA", functionalAreaWorkGroupIds)
        },
        toggleViewFAInfo(newValue) {
            this.showViewFAInfoTooltip = newValue
        },
        toggleCreateFAInfo(newValue) {
            this.showCreateFAInfoTooltip = newValue
        }

    },
    watch: {
        accountId: {
            deep: true,
            handler() {
                this.fetchFunctionalAreas()
            }
        }
    }
}
</script>

<style scoped>
    .c-icon{
    fill: #B0BEC5;;
    margin-right:15px;
    width: 18px;
    height: 18px;
    margin-right: auto;
    } 

    .middle-align {
        vertical-align: middle;
    }

    .icon-pointer:hover {
        cursor: pointer;
    }

    .fa-icon {
        display: inline-block;
    }

    /* style to override the sideNav's light colored text */
    .black-text{
        color: #444;
    }

    /* info tooltip styles for the list & plus functional area icon -- pulled from the InfoTooltip common component */
    .info-text{
        position: absolute;
        z-index: 999999;
        margin-left: -6px;
        margin-top: -2px;
    }

    .main-info-box{
        background: #FFFFFF;
        border: 0.5px solid #B0BEC5;
        box-sizing: border-box;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
        padding:20px;
        font-size:12px;
        width: fit-content;
        line-height: 175%;
        text-align: left;;
    }

    .arrow-up {
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 11px solid #B0BEC5;
    }

    .arrow-holder{
        padding-left:9px;
    }

</style>
