<template>
  <footer class="footer-dark" role="contentinfo">
    <div class="footer-dark__inner">
      <ul class="footer-ul">

       

        <li class="footer-item footer-version-txt"> 
            <span v-html='"T-K by CKM Analytix, version " + this.appVersion +  dataRangeString + " &mdash;  Display Time Zone: " + timeZoneLabel'></span> 
        </li>

      
      </ul>
    </div>
  </footer>
</template>

<script>
import moment from 'moment-timezone'
  export default {
    data: () => ({
      url: 'https://ckmanalytix.com/',
      text: 'Toolkit — &copy;2019'
    }),
    props: {
      dataRange: {type: Object, required: true},
      accountConfiguration: {type: Object, required: true}
    },
    computed: {
      appVersion () {
        // split the app version string into an array of substrings & then join them without the date substring at the end
        return process.env.APP_VERSION
      },
      timeZoneLabel() {
        let offset = this.accountConfiguration.timeZone ? moment.tz(this.accountConfiguration.timeZone).format("Z")
          : moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("Z")
        let timezone = this.accountConfiguration.timeZone ? this.accountConfiguration.timeZone 
          : Intl.DateTimeFormat().resolvedOptions().timeZone;

        return `[UTC${offset}] ${timezone}`
      },
      dataRangeDisabled() {
        return this.accountConfiguration.dataRangeDisabled
      },
      dataRangeString() {
        if(!this.dataRangeDisabled) {
          let { minDate, maxDate } = this.dataRange
          let minDateString = moment(minDate, "MM/DD/YYYY").format("DD MMM YYYY")
          let maxDateString = moment(maxDate, "MM/DD/YYYY").format("DD MMM YYYY")
          return " &mdash; Date Range: " + minDateString + " to " + maxDateString
        }
        return ""
        
      }
    }
  }
</script>

<style scoped>

.footer-dark__inner {
  display: flex;
  justify-content: center;
  font-size: 10px;
  margin-bottom: 5px;
}

</style>

