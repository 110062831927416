<template>
      <div class="c-primary-nav__item">
        <li class="c-primary-nav__item">
            <div class="c-primary-nav__link" role="button">
                <svg class="c-icon c-primary-nav__icon right-margin" aria-hidden="false" @click="toggleShowMenu" data-cy="navigationDropdown">
                    <use
                        :href="`../../assets/style/ckm-style/images/icons/icons.svg#chevron-` + (isMenuShown ? 'up' : 'down')"
                    />
                </svg>
            </div>
        </li>

        <div v-if="isMenuShown" class="fixed submenu">
            <ul class="c-primary-nav__list-right">
              <li 
                class="c-primary-nav__subitem"
                @click='clearAccount'  
                >
                    <router-link
                            to="/"
                            tag="a"
                            class="c-primary-nav__sublink"
                            data-cy="homeHeader"
                            exact
                        >
                            Home
                    </router-link>
                </li>
                <li 
                  class="c-primary-nav__subitem"
                  @click='toggleShowMenu'  
                >
                    <router-link
                        v-if="isAdmin"
                        to="/admin"
                        tag="a"
                        class="c-primary-nav__sublink"
                        data-cy="adminHeader"
                        exact
                    >
                        Admin
                    </router-link>
                </li>
                <li class="c-primary-nav__subitem">
                    <a @click="logout" class="c-primary-nav__sublink" data-cy="logoutButton" style="cursor:pointer;">
                        Logout
                    </a>
                </li>
            </ul><!-- end c-primary-nav__sublist js-nav-dropdown -->
        </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['label', 'dropDown'],
    data(){
        return {
            showMenu: false,
        }
    },
    computed:{
        ...mapGetters([
           'isAdmin'
        ]),
        isMenuShown() {
          return this.$props.label === this.$props.dropDown
        }
    },
    methods: {
        toggleShowMenu () {
          this.$emit('toggleShowMenu', this.$props.label)
        },
        clearAccount() {
          this.$store.dispatch('resetPage')
          this.toggleShowMenu()
        },
        logout () {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        }
    }
}
</script>

<style scoped>
    .right-margin {
        margin-right: 1rem;
    }

    .fixed {
        position: fixed;
    }

    .submenu {
        width: 10rem;
        right: .5rem;
        color: #2F2F2F;
        top: 4rem;
        background-color: white;
    }
</style>
