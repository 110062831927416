<template>
    <div class="multiselect-container">
        <div class="multiSelect">
            <multiselect
                v-model="values"
                :options="generateOptions"
                :multiple="true"
                track-by="value"
                :custom-label="customLabel"
                :show-labels="false"
                :block-keys="['Enter']"
                :close-on-select="false"
                :searchable="isSearchable"
                :class="getClass"
                :group-values="groupValues"
                :group-label="groupLabel"
                :group-select="true"
                :placeholder="placeholder"
                open-direction="bottom"
                @close="close"
                @open="open"
                @remove="remove"
                @input="input"
                ref="nativeChild"
            >
                <span class="checkbox-label c-label--checkbox" slot="option" slot-scope="scope" @mousedown="select(scope.option)">
                    <span v-if="scope.option.$isLabel && scope.option.$groupLabel">{{ scope.option.$groupLabel }}</span>
                    <input  :id="scope.option.value" class="dropdownInput c-input--checkbox" type="checkbox" v-model="scope.option.selected" />
                    {{ scope.option.key }}
                    <div class="c-input--checkbox__indicator" v-if="scope.option.$groupLabel || !scope.option.$isLabel" />
                </span>
            </multiselect>
        </div>
        <button  class="clear-button" @click="clearAll()"> X </button>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect'

export default {
    name: "MultiSelect",
    components: { Multiselect },
    props: {
        dropdownOptions: {
            type: Array
        },
        isSearchable: {
            type: Boolean,
            default: true
        },
        groupValues:{
            type: String
        },
        groupLabel: {
            type: String
        },
        valuesProp: {
            type: Array
        },
        bgColor: {
            type: String,
            default: '#6e7477'
        },
        placeholder: {
            type: String,
            default: 'Search'
        }
    },
    data() {
        return {
            values: [],
            groupLabelObject: Object
        }      
    },
    computed: {
        generateOptions() {
            return this.dropdownOptions;
        },
        getValues(){
            return this.dropdownOptions[0][this.groupValues].filter(obj => obj.selected)
        },
        getClass(){
            if (!this.isSearchable){
                return 'mymultiselect mymultiselect_nosearch'
            }
            return 'mymultiselect'
        }
    },
    methods: {
        clearAll(){
            this.values = []
            this.input(this.values, null)
        },
        customLabel(option) {
            return option.key
        },
        select(option) {
            option.selected = !option.selected;
            if(option.$isLabel){
                this.groupLabelObject = option;
            }
        },
        updateCheckBoxes() {
            var values = this.values.map(x => x.value);
            var opts = this.dropdownOptions[0][this.groupValues].map(x => x.value);
            var selectedVals = [];
            
            for (var x in opts) {
                let opt = opts[x];
                if (values.includes(opt)) {
                    this.dropdownOptions[0][this.groupValues].find(y => y.value === opt).selected = true;
                    selectedVals.push(opt);
                }
                else {
                    this.dropdownOptions[0][this.groupValues].find(y => y.value === opt).selected = false;
                }
            }  
            
            this.$emit('values', selectedVals);
            
        },
        close(value, id){
            if (this.isSearchable){
                this.$refs.nativeChild.$refs.search.style.display = "none"
            }
            if (this.values.length == 0){
                let tagsHtml = this.$refs.nativeChild.$refs.tags.innerHTML
                this.$refs.nativeChild.$refs.tags.innertHTML = 'Nothing selected' + tagsHtml
            }
        },
        open(id){
            if (this.isSearchable){
                this.$refs.nativeChild.$refs.search.style.display = "unset"
            }
        },
        remove(removedOption, id){
            if(this.groupLabelObject.selected){
                this.groupLabelObject.selected = false
            }
            removedOption.selected = false
            
        },
        input(value, id){
            this.updateCheckBoxes();
            this.$emit('input', value);
        },
        updateColors(){
            for (var ref in this.$refs.nativeChild.$refs){
                this.$refs.nativeChild.$refs[ref].style["background-color"] = this.bgColor
            }
        }
    },
    mounted(){
        this.values = this.getValues
        this.updateColors()
    }
}
</script>


<style scoped>
.multiSelect {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(1, 1, 1, 0);
}

.multiselect-container {
    display: flex;
}

.clear-button {
    border-radius: 5px;
    background-color: rgb(110,116,119); 
    color: white;
    outline:none;
}

.multiselect >>> .multiselect__input {
    font-size: 10px;
}

.multiSelect >>> .multiselect, .multiSelect >>> .multiselect__tags {
    min-height:  unset;
    height: 100%;
}

.multiSelect >>> .multiselect__tags {
    transition: all 0.1s ease-in-out;
    font: 1rem "Nunito Regular", Helvetica, sans-serif;
    line-height: 1.5rem;
    font-size: 12px;
    height:2.5rem;
    overflow: scroll;
    /* the two below hide the scroll bars for their respective browswers */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.multiSelect >>> .multiselect__tags::-webkit-scrollbar { 
    /* the below hides the scroll bars for their respective browswers */
    display: none;  /* Safari and Chrome */
}

.multiSelect >>> .multiselect__tag-icon:after {
    content: "\D7";
    font-size: 14px;
}

.multiSelect >>> .multiselect__tag-icon {
    cursor: pointer;
    margin-left: 0px;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    transition: all .2s ease;
    border-radius: 5px;
}

.multiSelect >>> .multiselect__tags-wrap {
    padding-left: 3px;
}

.multiSelect >>> .multiselect__tag {
    display: inline-flex;
    padding-left: 5px;
    border: 1px solid;
    border-radius: 5px;
    margin: 1px;
}

.multiSelect >>> .multiselect--active .multiselect__select{
    border-left: none;
}

.mymultiselect >>> .multiselect__option{
    padding: unset;
    min-height: unset;
    line-height: unset;
}

.mymultiselect >>> .multiselect__option--group {
    min-height: 40px;
}

.mymultiselect >>> .multiselect, 
.mymultiselect >>> .multiselect__input, 
.mymultiselect >>> .multiselect__single {
    font-size: 10px;
}

.mymultiselect >>> .multiselect__option--selected {
    background: #fff;
}

.mymultiselect >>> .multiselect__option--selected {
    font-weight: unset;
}

.mymultiselect >>> ul {
    padding-inline-start: 0px !important;
}

.mymultiselect >>> .c-label--checkbox {
    padding: .25rem 0 .25rem 3rem;
}

.mymultiselect >>> .c-input--checkbox__indicator {
    top: .285rem;
    left: 1rem;
}

.mymultiselect >>> .multiselect__content-wrapper {
    height: 180px;
    width: 225px;
    position: absolute;
    background-color: rgb(110,116,119); 
    border-radius: 0px 0px 3px 3px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-color: rgba(80, 134, 104, 0.5);
    z-index:10000;
    margin-top: 32px;
    overflow: scroll;
    /* the two below hide the scroll bars for their respective browswers */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.mymultiselect_nosearch >>> .multiselect__content-wrapper {
  margin-top: 1px;
  height: 96px;
  border-top: 1px solid;
  border-color: rgba(80, 134, 104, 0.5);
}

.mymultiselect >>> .multiselect__content::-webkit-scrollbar { 
    /* the below hides the scroll bars for their respective browswers */
    display: none;  /* Safari and Chrome */
}

.mymultiselect{
    background-color: rgb(110,116,119); 
    border-radius: 3px;
    border: 1px solid rgba(80, 134, 104, 0.5);
}

.multiSelect >>> .multiselect__input {
    position: absolute;
    top: 148px;
    font-size: 14px;
    width: 225px !important;
    height:32px;
    background-color: rgb(110,116,119);
    border-radius: 3px 3px 0px 0px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    border-color: rgba(80, 134, 104, 0.5);
    z-index:10000;
    color: white;
    display:none;
}
.multiSelect >>> .multiselect__input:focus{
    outline:none;
}
.multiSelect >>> .multiselect__input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.multiSelect >>> .multiselect__placeholder{
    display:none;
}

.mymultiselect_nosearch >>> .multiselect__placeholder{
    display:flex;
    padding: 5px;
}


</style>

