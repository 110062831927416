<template>
    <div class="table-container">
        <div class="table-header-row">
            <div
                v-for="(column, index) in columnNames"
                :key="column"
                :class="['header-cell', centerAlignedColumns.includes(column) ? 'centered-cell' : '']"
                :style="`width:${widths[index]}%`"
            >
                <span style="white-space: pre-wrap;">{{ column }}</span>
                <SortDirectionTernary
                    v-if="sortableCells.includes(cells[index])"
                    :deliveredState="sortState[cells[index]]"
                    @sortAsc="$emit('sort', cells[index] + ',asc', cellTypes[index])"
                    @sortDesc="$emit('sort', cells[index] + ',desc', cellTypes[index])"
                />
            </div>
        </div>
        <div class="table-body">
            <!-- total row is separate and should not be affected by the sort (aka stay at the top of the table) -->
            <div
                v-if="includeTotalRow"
                key="totalRow"
                :class="['table-body-row', 'total-row', useRowEnter ? 'hover-row' : '']"
                @mouseenter="rowEnter(totalRowData)"
                @mouseleave="rowLeave(totalRowData)"                
            >
                <div
                    v-for="(cell, index) in cells"
                    :key="'totalRowCell'+index"
                    :class="['table-body-cell', centerAlignedCells.includes(cell) ? 'centered-cell' : '']"
                    :style="`width:${widths[index]}%`"
                >
                    <!-- show tooltip if this is one of the abbreviated cells and the text has exceded max character length -->
                    <div
                        v-if="Object.keys(abbreviatedCells).includes(cell) && totalRowData[cell].length > abbreviatedCells[cell]"
                        class="abbreviated-cell"
                        v-tooltip.bottom-start="totalRowData[cell]"
                    >
                        {{ totalRowData[cell] }}
                    </div>
                    <div v-else> {{ totalRowData[cell] }} </div>
                </div>
            </div>
            <div
                v-for="(row, index) in tableData"
                :key="index"
                :class="['table-body-row', useRowEnter ? 'hover-row' : '']"
                @mouseenter="rowEnter(row)"
                @mouseleave="rowLeave(row)"
            >
                <div
                    v-for="(cell, index) in cells"
                    :key="index"
                    :class="['table-body-cell', centerAlignedCells.includes(cell) ? 'centered-cell' : '']"
                    :style="`width:${widths[index]}%`"
                >
                    <!-- show tooltip if this is one of the abbreviated cells and the text has exceded max character length -->
                    <div
                        v-if="abbreviatedCells && Object.keys(abbreviatedCells).includes(cell) && row[cell].length > abbreviatedCells[cell]"
                        class="abbreviated-cell"
                        v-tooltip.bottom-start="row[cell]"
                    >
                        {{ row[cell] }}
                    </div>
                    <div v-else-if="clickableCells.includes(cell)">
                        <span v-if="showClickableCellText" @click="onCellClick(row, cell)" class="clickable-cell primary-color">{{ row[cell] }}</span>
                        <img v-else :src="getClickableIconImg(cell)" @click="onCellClick(row, cell)" class="open-button" alt="Open modal button">
                    </div>
                    <div v-else-if="linkedCells.includes(cell)">
                        <LinkCell
                            :link="makeLink(row)"
                        />
                    </div>
                    <div v-else> {{ row[cell] }} </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LinkCell from "@Common/tables/cells/LinkCell.vue"
import SortDirectionTernary from "@Common/SortDirectionTernary.vue"

export default {
    name: "ClientPaginatedTable",
    components: { LinkCell, SortDirectionTernary },
    props:{
        // this is a json object of the form { cellName : maxCharacterWidth }
        abbreviatedCells:{
            type: Object,
            required: false,
            default: () => {}
        },
        columnNames:{
            type: Array
        },
        tableData:{
            type: Array
        },
        cells:{
            type: Array
        },
        cellTypes: {
            type: Array
        },
        sortableCells:{
            type: Array,
            required: false,
            default: () => []
        },
        sortState:{
            type: Object,
            required: false,
            default: () => {}
        },
        widths:{
            type: Array
        },
        useRowEnter:{
            type: Boolean,
            default: false
        },
        useRowLeave:{
            type: Boolean,
            defualt: false
        },
        centerAlignedCells:{
            type: Array,
            required: false,
            default: () => []
        },
        centerAlignedColumns:{
            type: Array,
            required: false,
            default: () => []
        },
        clickableCells:{
            type: Array,
            required: false,
            default: () => []
        },
        // array of objects with keys 'name' and 'link'
        linkedCells:{
            type: Array,
            required: false,
            default: () => []
        },
        makeLink:{
            type: Function,
            required: false,
            default: (row) => {return ""}
        },
        // this is a json object of the form { cellName : iconFileName.svg }
        clickableIconImages:{
            type: Object,
            required: false,
            default: () => {}
        },
        // this is a data object with the aggregated totals and formatted the same as the rest of the tableData
        totalRow:{
            type: Object,
            required: false,
            default: () => {{}}
        },
        showClickableCellText:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        includeTotalRow() {
            // totalRow object exists & is not empty
            return Boolean(this.totalRow) && (Object.keys(this.totalRow).length !== 0)
        },
        totalRowData() {
            return this.totalRow
        }
    },
    methods: {
        getClickableIconImg(cell) {
            let icons = require.context('@Images/icons/', false, /^\.\/.*$/)
            // use default icon if clickableIconImages object is null or empty or if it doesn't contain the cell
            if ( !this.clickableIconImages || Object.keys(this.clickableIconImages).length === 0 || !this.clickableIconImages[cell]) {
                return icons('./icon_external_link_green.svg')
            } else return icons(`./${this.clickableIconImages[cell]}`)
        },
        rowEnter(row){
            if(this.useRowEnter) this.$emit("rowEnterEmit", row)
        },
        rowLeave(row){
            if(this.useRowLeave) this.$emit("rowLeaveEmit", row)
        },
        onCellClick(row, cell){
            this.$emit("onCellClick", row, cell)
        }
    }
}
</script>

<style scoped>
.table-container{
    display:flex;
    flex-direction: column;
}
.table-header-row{
    display: flex;
    flex-direction: row;
    background: rgba(39, 149, 251, 0.15);
    padding: 0.5rem;
    font-weight: bold;
    font-size: 12px;
}
.header-cell{
    display: flex;
}
.header-cell span{
    padding-left: 4px;
    padding-right: 4px;
    align-self: center;
    text-align: center;
}

.total-row{
    background: rgba(39, 149, 251, 0.08)
}

.table-body-cell{
    word-wrap: break-word;
    padding-right: 10px;
    display: flex;
    align-items: center;
}
.table-body-row{
    display:flex;
    flex-direction: row;
    font-size: 10px;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 0.5px solid rgba(39, 149, 251, 0.5);
}
.table-body{
    height:100%;
    overflow-y:auto;
}
.hover-row:hover{
    cursor: pointer;
    background: rgba(39, 149, 251, 0.1);
}
.abbreviated-cell{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.centered-cell{
    justify-content: center;
}
.open-button:hover{
    cursor: pointer;
}
.open-button{
    height: 15px;
}
.clickable-cell:hover{
    cursor: pointer;
}
.clickable-cell{
    text-decoration-line: underline;
}
</style>
