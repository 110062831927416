 <script>
export default {
    methods: {
        /*
        * Method for modifying/adding a new query parameters to the routers URL
        * keyValuePairs: Object in form of {key1: value1, key2: value2}
        */
        setRouteQueryParam(keyValuePairs) {
            let queryParams = {...this.$route.query, ...keyValuePairs}
            this.$router.push({path: this.$route.path, query: queryParams})
                .catch(err => {
                    console.log(err)
                })
        },
        /*
        * method for retrieving single query parameter from the url query parameters
        */
        getRouteQueryParam(key) {
            if (this.$route) {
                return this.$route.query[key]
            }
            return null
        },
        /*
        * component expects presence of certain query params in url, if they are not present, set them
        */
        defaultQueryParams(defaultParams) {
            let queryParams = {...defaultParams, ...this.$route.query}
            this.$router.push({path: this.$route.path, query: queryParams}).catch((err) => {
            })
        }
    }
}
</script>
