import moment from 'moment'

export const blankCategoryData = [null, '(blank)']
export const blankCategoryDisplay = '[Blank Category]'

export const getTickValues = (initialTickValues, tickCount) => {
    let i = Math.max(Math.round(initialTickValues.length / tickCount), 1)
    return initialTickValues.reduce(
        (tickValues, value, idx) => idx % i === 0 ? [ ...tickValues, value ] : tickValues,
        []
    )
}
export const consoleLog = (location, msg, level) => {
    level = level||'Info';
    var fmt_msg = `[${moment().utc().format('ddd, DD MMM YYYY HH:mm:SS')} GMT] : @tk/${location} : ${level} - ${msg}`;
    switch(level) {
        case 'Info': return console.info(fmt_msg);break;
        case 'Debug': return console.debug(fmt_msg);break
        case 'Warn' : return console.warn(fmt_msg); break;
        case 'Error': return console.error(fmt_msg)
    }
}
export const formatText = text =>
    text
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        .replace(/^./, (str) => str.toUpperCase())

// straight from stackoverflow bb
export const msToTime = (duration) => {
    let milliseconds = parseInt((duration%1000)/100)
        , seconds = parseInt((duration/1000)%60)
        , minutes = parseInt((duration/(1000*60))%60)
        , hours = parseInt((duration/(1000*60*60))%24)

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}

export const roundAndFormat = (value, decimals) => parseFloat((value || 0).toFixed(decimals));

/*
* functions for autoformatting a duration in milliseconds
* to something more aesthetically pleasing
*/
const divisorMap = {
    days: 1000*60*60*24, day: 1000*60*60*24, d: 1000*60*60*24,
    hours: 1000*60*60, hour: 1000*60*60, hr: 1000*60*60, h: 1000*60*60,
    minutes: 1000*60, minute: 1000*60, mins: 1000*60, min: 1000*60,
    seconds: 1000, secs: 1000, s: 1000
} // most of these keys aren't yet used, but for future compatibility
export const formatTimeByFormatType = (time, type, labeled=true, precision=1) => {
    // if invalid type is passed, parse to seconds by default
    let divisor = divisorMap[type] || 1000
    return (time/divisor).toFixed(precision) + (labeled ? ` ${type}` : "")
}

export const getTimeFormat = (time) => {
    switch(true) {
        case Math.abs(time) >= (3600000 * 24): // greater than 1 day
            return "days"
        case Math.abs(time) >= 3600000: // greater than 1 hr
            return "hours"
        case Math.abs(time) >= 100000: // greater than 100 seconds
            return "minutes"
        default:
            return "seconds"
    }
}

export const formatResolveTime = (time) => formatTimeByFormatType(time, getTimeFormat(time))

export const getNewTimeFormat = (time, type) => { 
    // if invalid type is passed, parse to seconds by default
    let multiplyer = divisorMap[type] || 1000
    return getTimeFormat( time * multiplyer ) 
}

export const convertTimeFormat = (time, oldtype, newtype, labeled=false) => {
    // if invalid type is passed, parse to seconds by default
    let multiplyer = divisorMap[oldtype] || 1000
    return formatTimeByFormatType( time * multiplyer, newtype, labeled )
}

/*
Converts a dow int to a formatted string of the dow
*/
export const intToDayOfWeek = (i) => {
    let dowMap = {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday"
    }
    return dowMap[i]
}

/*
Converts a 24 hour int to a formatted string with AM/PM
*/
export const twentyFourHourtoTwelveHour = (hour) => {
    let suffix;
    if (hour >= 12) {
        suffix = "PM"
    } else {
        suffix = "AM"
    }
    if (hour > 12) {
        return `${hour - 12} ${suffix}`
    } else if (hour === 0) {
        return `12 ${suffix}`
    } else {
        return `${hour} ${suffix}`
    }
}

/*
Converts a floating point rate to a percent (e.g. 0.15 => 15.0%)
*/
export const formatPercent = (fl, places=1, trend=null) => {
    if (fl == null) { return "-"}
    // if the trend argument is passed, show the percent increase, not the rate
    if (fl >= 1.0 && trend == "increase") {
        fl = fl-1
    }
    else if (trend == "decrease") {
        fl = 1-fl
    }
    return `${(fl*100).toFixed(places)}%`
}

export const formatValue = (valueType, value) => {
    switch(valueType) {
        case "milliseconds":
            return formatResolveTime(value)
        case "seconds":
            return formatResolveTime(value * 1000)
        case "percent":
            return formatPercent(value, 1, null)
        case "percentIncrease":
            return formatPercent(value, 1, "increase")
        case "percentDecrease":
            return formatPercent(value, 1, "decrease")
        case "fte":
            return `${roundAndFormat(value, 2)} FTE`
        case "percentile":
            return `the ${ordinalSuffixOf(value*100)} percentile`
        case "deviation":
            let isInt = value %1 === 0
            let formattedVal = isInt ? value.toFixed(1) : value
            return `${formattedVal} standard deviations`
        default:
            return value
    }
}

export const ordinalSuffixOf= (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export const simplifyBigInteger = (bigint) => {
    switch(true) {
        case bigint < 1000:
            return bigint 
        case bigint < 1000000:
            return (bigint / 1000).toFixed(0) + "K"
        case bigint < 1000000000:
            return (bigint / 1000000).toFixed(0) + "M"
        case bigint < 1000000000000:
            return (bigint / 1000000000).toFixed(0) + "G"
        default:
            return bigint // add more suffixes here if necessary
    }
}


export const formatDuration = (duration, forceHours=true, roundMinutes=false) => {
    let text = ""
    if(duration != null){
        let mttrDur = moment.duration(duration)
        if(roundMinutes){
            let roundedMinutes = Math.round(mttrDur.asMinutes())
            mttrDur = moment.duration(roundedMinutes, 'minutes')
        }
        let days = Math.floor(mttrDur.asDays())
        let hours = mttrDur.hours()
        let minutes = mttrDur.minutes()
        
        if (Math.abs(days) > 0) { text = text + `${days}d `}
        if(days > 0 || hours > 0 || forceHours){
            text = text + `${hours}h `
        }
        text = text + `${minutes}m`
    }
    return text
}


export const formatMs = (mttr) => {
        if (mttr == null) {return "-"}
        let days = Math.floor(mttr/(1000*60*60*24))
        let mttrDur = moment.duration(mttr)
        let hours = mttrDur.hours()
        let minutes = mttrDur.minutes()
        let text = ""
        if (days > 0) { text = text + `${days}d `}
        text = text + `${hours}h ${minutes}m`
        return text
}

export const formatMsSeconds = (mttr) => {
    if (mttr == null) {return "-"}
    let days = Math.floor(mttr/(1000*60*60*24))
    let mttrDur = moment.duration(mttr)
    let hours = mttrDur.hours()
    let minutes = mttrDur.minutes()
    let seconds = mttrDur.seconds()
    let text = ""
    if (days > 0) { text = text + `${days}d `}
    text = text + `${hours}h ${minutes}m`
    if (days == 0) { text = text + ` ${seconds}s`}
    return text
}  

export const titleCase = (str) => {
    return str.replace(
        /\w\S*/g, 
        function(elm) {
            return elm.charAt(0).toUpperCase() + elm.substr(1).toLowerCase()
        }
    )
}

export const formatDatetime = (datetime) => {
    if (datetime == null) {
        return ""
    } else {
        return moment(datetime).format('MMM DD, YYYY, hh:mm:ss A')
    }
}

export const formatDate = (datetime) => {
    if (datetime == null) {
        return ""
    } else {
        return moment(datetime).format('MMM DD, YYYY')
    }
}

export const servideDeskDashboard = 1;
export const catstatsDashboard = 2;
export const processMiningDashboard = 3;
export const wamDashboard = 5;

// Used by processMapVisualization components to format mttr, mean clearance time & effort per case value
export const durationify = (seconds) => {
    seconds = Number(seconds);
    if (seconds == 0) return "0s";
    if (seconds < 1) return "<1s";
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + "d " : "";
    var hDisplay = h > 0 ? h + "h " : "";
    var mDisplay = m > 0 ? m + "m " : "";
    var sDisplay = s > 0 ? s + "s " : "";
    return dDisplay + hDisplay + mDisplay + sDisplay
}

