<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container black-text">

                <div class="modal-body-and-foot-container">
                    <div class="modal-header">
                        <h2><strong>{{ modalLabel }}</strong></h2>
                        <img
                            src="@Images/icons/icon_delete.svg"
                            @click="closeModal"
                            class="delete-icon"
                            alt="Delete Icon"
                        >
                    </div>
                    <div class="modal-body">
                        <div class="view-fa-table-container c-card">
                            <ClientPaginatedTableContainer
                                :columnNames="functionalAreaTableColumns"
                                :cells="functionalAreaTableCells"
                                :cellTypes="cellTypes"
                                :sortableCells="functionalAreaTableSortableCells"
                                :defaultSort="functionalAreaTableDefaultSort"
                                :widths="functionalAreaTableWidths"
                                :responseTransform="functionalAreaTableResponseTransform"
                                :abbreviatedCells="functionalAreaTableAbbreviatedCells"
                                :centerAlignedCells="functionalAreaTableCenterAlignedCells"
                                :centerAlignedColumns="functionalAreaTableCenterAlignedColumns"
                                :clickableCells="functionalAreaTableClickableCells"
                                :useFetchData="false"
                                :tableDataProp="existingFAs"
                                :searchEnabled="false"
                                :clickableIconImages="functionalAreaTableIconImages"
                                @onCellClick="onCellClick"
                            />
                        </div>
                        <ConfirmSaveModal
                            v-if="showConfirmDeleteModal"
                            :propTitle="confirmDeleteModalText.title"
                            :propMessage="confirmDeleteModalText.msg"
                            :propConfirmBtnMsg="confirmDeleteModalText.confirmBtnMsg"
                            :propCancelBtnMsg="confirmDeleteModalText.cancelBtnMsg"
                            @closeModal="closeConfirmDeleteModal"
                            @confirmSave="deleteFunctionalArea"
                        />
                    </div>
                    <div class="modal-footer" >
                        <div v-if="displayStatus==='success'" class="flex-row">
                            <img
                                src="@Images/icons/icon_checkmark.svg"
                                class="ticket-icon"
                                alt="Ticket icon"
                            > 
                            <div>
                                <span class="success-failure-label">
                                Functional Area Deleted!
                                </span>
                            </div>
                        </div>                        
                        <div v-else-if="displayStatus==='loading'" class="loader-tiny"></div>
                        <div v-if="displayStatus==='failure'" class="flex-row">
                            <img
                                src="@Images/icons/icon_delete.svg"
                                class="ticket-icon"
                                alt="Ticket icon"
                            > 
                            <div @click="reEnableForm">
                                <span class="c-error success-failure-label c-clickable">
                                    {{ failureText }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import { axiosRequests } from '@/main'
import RouteQueryParamMixin from '@Mixins/RouteQueryParamMixin.vue'
import _ from 'lodash';
import functionalAreaTable from '@Common/functionalArea/functionalAreaTable.json'
import ClientPaginatedTableContainer from "@Common/ClientPaginatedTableContainer.vue"
import ConfirmSaveModal from '@Common/ConfirmSaveModal.vue'

export default {
    name: "ViewFunctionalAreaModal",
    components: { ClientPaginatedTableContainer, ConfirmSaveModal },
    mixins: [RouteQueryParamMixin],
    props:{
        existingFunctionalAreas: { type: Array, required: false, default: () => [] },
        accountId: { type: [String, Number], required: true },
        accountConfiguration: { type: Object, required: true },
        workGroupsProp: { type: Array } // Array of wkgrp objects structured as {"key":"Name of wkgrp","value":1234,"selected":false}
    },
    data(){ 
        return {
            modalLabel: "Select Functional Area",
            displayStatus: "submitButton",
            failureText: "Failed to Save Functional Area",
            showConfirmDeleteModal: false,
            confirmDeleteModalText: {
                title: "Are you sure you want to delete?",
                msg: "This action cannot be undone",
                confirmBtnMsg: "DELETE",
                cancelBtnMsg: "GO BACK"
            },
            deleteFunctionalAreaId: null
        }
    },
    computed: {
        existingFAs() {
            return this.existingFunctionalAreas
        },
        functionalAreaTableConfiguration(){
            return functionalAreaTable
        },
        /*
        computed properties for functional area table config
        */
        functionalAreaTableIconImages() {
            // icon fileNames for static assets located in '@Images/icons/' directory for clickable cells
            return this.functionalAreaTableConfiguration["clickableIconImages"]
        },
        functionalAreaTableColumns() {
            return this.functionalAreaTableConfiguration["columnNames"]
        },
        functionalAreaTableCenterAlignedColumns() {
            return this.functionalAreaTableConfiguration["centerAlignedColumns"]
        },
        functionalAreaTableCells() {
            return this.functionalAreaTableConfiguration["cells"]
        },
        cellTypes() {
            return this.functionalAreaTableConfiguration["cellTypes"]
        },
        functionalAreaTableSortableCells() {
            return this.functionalAreaTableConfiguration["sortableCells"]
        },
        functionalAreaTableDefaultSort() {
            return this.functionalAreaTableConfiguration["defaultSort"]
        },
        functionalAreaTableWidths() {
            return this.functionalAreaTableConfiguration["widths"]
        },
        functionalAreaTableCenterAlignedCells() {
            return this.functionalAreaTableConfiguration["centerAlignedCells"]
        },
        functionalAreaTableClickableCells() {
            return this.functionalAreaTableConfiguration["clickableCells"]
        },
        /*
        * this is a json object of the form { cellName : maxCharacterWidth }
        */
         functionalAreaTableAbbreviatedCells(){
            return this.functionalAreaTableConfiguration["abbreviatedCells"]
        },

    },

    methods: {
        onCellClick(row, cell){
            if (cell == "select") {
                this.$emit("selectFA", row.includedWorkGroupIds)
                this.closeModal()
            } else if (cell == "delete") {
                this.deleteFunctionalAreaId = row.id
                this.showConfirmDeleteModal = true
            }
        },
        functionalAreaTableResponseTransform(responseData) {
            return responseData.map(obj => ({
                ...obj,
                workgroups: this.workGroupsProp.filter( (wkgrp) => obj.includedWorkGroupIds.includes(wkgrp.value)).map((wkgrp)=> wkgrp.key).join(', ')
            }))
        },
        closeModal() {
           if (this.displayStatus != "loading") {
            this.$emit("closeModal")
           }
        },
        /**
         * Method for saving a new functional area
         */
        deleteFunctionalArea(){
            this.closeConfirmDeleteModal()
            this.displayStatus = "loading"
            let deleteUrl = `functionalArea/${this.deleteFunctionalAreaId}`
            axiosRequests
                .delete(deleteUrl)
                .then(() => {
                    this.displayStatus = "success"
                    this.$emit("removeDeletedFA", this.deleteFunctionalAreaId)
                    this.deleteFunctionalAreaId = null
                })
                .catch(error => {
                    this.displayStatus = "failure"
                    console.log(error)
                    console.log(error.response.data.message)
                    this.deleteFunctionalAreaId = null
                })
        },
        closeConfirmDeleteModal() {
            this.showConfirmDeleteModal = false
        }
    }
}


</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 17rem;
    width: 86vw;
    height: 100%;
    background-color: rgba(176, 190, 197, 0.5);
}

.modal-container {
    width: 58vw;
    max-height: 95vh;
    margin: 1.75rem 8rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    box-shadow: -4px 4px 4px rgba(49, 48, 48, 0.1);
    border-radius: 0.6rem;
    display:flex;
    flex-direction: column;
    font-size:16px;
}

.modal-header {
  margin-top: 0;
  padding-top: 0.5rem ;
  border-bottom: 1px solid #444;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.modal-body {
    padding-top:  2rem;
    display: block;
    align-items: right
}
.modal-footer{
    padding-top: 1rem;
    width: 100%;
    margin-bottom: 25px;
}

.delete-icon {
    width: 2rem;
    cursor: pointer;
}

.base-info{
    display: table;
    position: relative;
    width: 100%;
    align-items: center;
    padding-bottom: 5px;
}

.base-info-item{
    display: table-cell;
}

.config-section{
    margin-top: 2rem;
}

.section-header {
    margin-bottom: 1rem;
    text-decoration: underline;
}

thead, tbody{
 border-bottom: 1px solid black;
}

td{
    padding:0.25rem 0 0.25rem 0;
}

tr{
    padding-bottom: 0.25rem;
}

.c-input--text, .c-input--select{
    width: 5.5rem;
    height: 2rem;
    font-size: 16px;
}

.target-input{
    display: inline-flex;
    position: relative;
}

.target-unit, .target-unit-static {
    margin: 0 0.5rem;
}

.target-unit-static{
    padding-top:0.5rem;
}

.share{
    padding-top:0.5rem;
    width: 30%;

}

.column-weight{
    width: 20%;
}
.column-status{
    width: 20%;
}

.column-target{
    width: 25%;
}

.disabled{
    color: gray;
}

.error-message-tooltip{
    position:absolute;
    bottom:100%;
    white-space: nowrap;
    font-size: 12px;
    content: '';
    background-color: #FED0D0;
}

.error-message-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #FED0D0 transparent transparent transparent;
}

.submit-button-container{
    display: flex;
    justify-content: center;
}
.footer-btn{
    width: 40%;
    margin: 1rem;
}

.c-btn:disabled{
    background-color: gray;
    opacity: 40%;
    cursor: default;
}

.flex-column{
    display: flex;
    flex-direction: column;
}
.flex-row{
    display: flex;
    justify-content: center;
}
.success-failure-label{
    font-size: 20px;
}
.ticket-icon{        
    height: 35px;
    width: 35px;
    margin-right: 7px;
}
.c-clickable{
    cursor: pointer;
}
.active-switch{
    vertical-align: middle;
    margin-right: 10px;
} 

/* style for the exclusion lists */
.list-container{
    display:flex;
    justify-content: space-evenly;
}
.margin-right{
    margin-right:10px;
}

/* style to simulate disabling dropdown & exclusion lists after saving functionalArea */
.div-disabled{
    background-color: gray;
    opacity: 40%;
    cursor: default;
}

/* style to override the sideNav's light colored text */
.black-text{
    color: #444;
}

.view-fa-table-container{
    padding: 0;
}



</style>

