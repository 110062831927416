<template>
  <transition name="fade">
    <div v-if="errorMessage"class="c-error error-fixed"> {{ errorMessage }} </div>
  </transition>
</template>

<script>
import SideMenu from '@Common/SideMenu.vue'

export default {
    computed: {
        errorMessage () {
           return this.$store.state.errorMessage
        }
    }
}
</script>



<style scoped>
    .error-fixed{
      position: fixed;
      top: 15%;
      left: 10%;
      z-index:15;
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
    }
</style>
