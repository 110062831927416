<template>
    <div class='sort-icon-container'>
        <svg
            class='sort-icon'
            :class='iconState[0]'
            @click='upperClickHandler()'
        >
            <use :href='iconsPath + "icon_sort_upper.svg#tallTriangle"'/>
        </svg>
        <svg
            class='sort-icon'
            :class='iconState[1]'
            @click='lowerClickHandler()'
        >
            <use :href='iconsPath + "icon_sort_lower.svg#tallTriangle"'/>
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        //In radio mode, clicks to the currently 'selected' arrow are
        //ignored, just as clicks to the selected circle of a radio
        //button are ignored. The other mode can be considered 'toggle
        //mode,' in which any click on the full area of the toggle
        //'button' registers the state change.
        radioMode: {
            type: Boolean,
            default: false
        },
        deliveredState: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            state: this.deliveredState
        }
    },
    computed: {
        iconsPath() {
            return process.env.IMAGES_PATH + "icons/";
        },
        iconState() {
            if (this.state < 0) {
                return ['off', 'on']
            } else if (this.state > 0) {
                return ['on', 'off']
            } else {
                return ['off', 'off']
            }
        }
    },
    watch: {
        deliveredState: function(newState, _) {
            this.state = newState
        }
    },
    methods: {
        // The component could take its state purely from the parent
        // with deliveredState rather than storing its state internally.
        // This design gives greater flexibility, so that the parent
        // is not required to pass back the changed state when handling
        // the emit.
        upperClickHandler() {
            if (this.state == 0 ||
                (this.radioMode && (this.state < 0))
            ) {
                this.state = 1
                this.$emit('sortAsc')
            } else if (!this.radioMode) {
                this.state *= -1
                this.$emit(this.state > 0 ? 'sortAsc' : 'sortDesc')
            }
        },
        lowerClickHandler() {
            if (this.state == 0 ||
                    (this.radioMode && (this.state > 0))
            ) {
                this.state = -1
                this.$emit('sortDesc')
            } else if (!this.radioMode) {
                this.state *= -1
                this.$emit(this.state > 0 ? 'sortAsc' : 'sortDesc')
            }
        }
    }
}
</script>

<style scoped>
    .sort-icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .sort-icon {
        /* viewBox has width:height ratio 48:33 */
        height: 0.4rem; /* ~so roughly 1rem lineheight for full icon */
        width: calc(0.4rem * 48/33);
        cursor: pointer;
    }
    .sort-icon.off {
        fill: #808e95;
    }
</style>
